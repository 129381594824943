import http from "../http-common";

class ClassCollectionDataService {
  getAll() {
    return http.get("/collections");
  }
  getAllForSelection() {
    return http.get("/collections?selection=true");
  }
  get(id) {
    return http.get(`/collections/${id}`);
  }
  create(data) {
    return http.post(`/collections`, data);
  }
  update(id, data) {
    return http.put(`/collections/${id}`, data);
  }
  delete(id) {
    return http.delete(`/collections/${id}`);
  }
}

const CollectionDataService = new ClassCollectionDataService();
export default CollectionDataService;
