import http from "../http-common";

class ClassVersionDataService {
  getAll() {
    return http.get("/versions");
  }

}

const VersionDataService = new ClassVersionDataService();
export default VersionDataService;
