import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import GameDataService from "../../services/game.service";
import LocationDataService from "../../services/location.service";
import CategoryDataService from "../../services/category.service";
import UserDataService from "../../services/user.service";
import { useAlertMessageStore, useHeaderStore } from "src/store/store";

import { GameForm } from "src/components/game";
import Loading from "src/components/Loading";

const AddGame = ({ history }) => {
  const [selectedGame, setSelectedGame] = useState({});
  const [loading, setLoading] = useState(true);
  const [maingameList, setMaingameList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const collection_id = localStorage.getItem("collectionID");

  const initialValues = {
    name: "",
    ean: "",
    designer: "",
    publisher: "",
    minPlayers: "",
    maxPlayers: "",
    minAge: "",
    playtime: "",
    datePurchased: null,
    yearpublished: "",
    coop: false,
    recordPoints: false,
    maingame: null,
    rating: 0,
    description: "",
    categoryList: [],
    location: null,
    image: "",
    bgdbID: "",
    collectionID: collection_id,
    addMultiple: false,
  };

  useEffect(() => {
    setLoading(true);
    useHeaderStore.setState({
      title: "Spiel hinzufügen",
      help: "addGame",
    });
    checkAccountType();
    retrieveCategories();
    retrieveLocations();
    retrieveMainGames();
  }, []);

  const checkAccountType = useCallback(() => {
    UserDataService.checkAccountType("addGame", collection_id).catch((e) => {
      useAlertMessageStore.setState({
        alertMessage: {
          severity: "error",
          message: `error.${e.response.data.message}`,
        },
      });
      history.push(`/`);
    });
  }, [collection_id, history]);

  /* const handleAddMultiple = () => {
    useAlertMessageStore.setState({
      alertMessage: {
        severity: "success",
        message: "Das Spiel wurde erfolgreich hinzugefügt",
      },
    });
    setState((prevState) => ({
      ...prevState,
      searchName: "",
      searchResults: false,
      BGDBGames: [],
    }));
    retrieveCategories();
    retrieveLocations();
    retrieveMainGames();
  };*/

  const retrieveCategories = () => {
    CategoryDataService.getAll()
      .then((response) => {
        setCategoryList(response.data);
      })
      .catch((e) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Laden der Kategorien",
          },
        });
      });
  };

  const retrieveLocations = () => {
    LocationDataService.getAll(collection_id)
      .then((response) => {
        setLocationList(response.data.locations);
      })
      .catch((e) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Laden der Standorte",
          },
        });
      });
  };

  const retrieveMainGames = () => {
    GameDataService.getMainGames(collection_id)
      .then((response) => {
        setMaingameList(response.data.data);
        setLoading(false);
      })
      .catch((e) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Laden der Hauptspiele",
          },
        });
      });
  };

  const handleFormSubmit = (values, resetForm) => {
    setLoading(true);
    GameDataService.create(values)
      .then((response) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: `Das Spiel wurde erfolgreich hinzugefügt`,
          },
        });
        if (values.addMultiple) {
          resetForm();
        } else {
          history.push(`/games/${response.data.gameID}`);
        }
      })
      .catch((e) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Hinzufügen des Spiels",
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <GameForm
        handleFormSubmit={handleFormSubmit}
        initialValues={initialValues}
        selectedGame={selectedGame}
        maingameList={maingameList}
        locationList={locationList}
        categoryList={categoryList}
        submitText="Spiel hinzufügen"
        submitMultipleText="Spiel hinzufügen & Neu"
        action="add"
      />
    </>
  );
};

export default AddGame;
