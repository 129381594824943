import React, { useState, useEffect } from "react";
import { useAlertMessageStore, useHeaderStore } from "src/store/store";
import BGDBDataService from "src/services/bgdb.service";

import { Grid } from "@mui/material";

import Loading from "src/components/Loading";
import { DiscoverGameCard } from "src/components/game";

const DicoverPage = () => {
  const [loading, setLoading] = useState(false);
  const [gameList, setGameList] = useState([]);

  useEffect(() => {
    setLoading(true);
    useHeaderStore.setState({
      title: "Entdecken",
      help: false,
    });

    const fetchGames = async () => {
      await BGDBDataService.discover()
        .then((response) => {
          setGameList(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message: `Fehler beim Laden der Spiele`,
            },
          });
        });
    };
    fetchGames();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <Grid container spacing={3}>
      {gameList.map((game) => {
        return (
          <Grid
            item
            sx={{ py: "12px" }}
            xs={12}
            sm={6}
            lg={4}
            xl={3}
            xxxl={3}
            key={game.id}
          >
            <DiscoverGameCard
              name={game.name}
              id={game.bgdbID}
              minAge={game.minAge}
              minPlayers={game.minPlayers}
              maxPlayers={game.maxPlayers}
              playtime={game.playtime}
              yearpublished={game.yearpublished}
              profilePicture={game.image}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DicoverPage;
