import { create } from 'zustand';


export const useGameSearchStore = create( (set, get) => ({
    searchString: "",
    categoryFilter: "",

    updateSearchString: (newSearchString) => {
        set({ searchString: newSearchString })
    },
    resetSearchString: () => {
        set({ searchString: "" })
    },
}));


export const useCollectionStore = create( (set, get) => ({
    collectionID: "",
}));

export const useAlertMessageStore = create( (set, get) => ({
    alertMessage: false,

    updateAlertMessage: (newAlertMessage) => {
        set({ alertMessage: newAlertMessage })
    },
    resetAlertMessage: () => {
        set({ alertMessage: false })
    },
}));


export const useHeaderStore = create( (set, get) => ({
    title: false,
    help: "",

    updateHeader: (newHeader) => {
        set({ title: newHeader.title, help: newHeader.help })
    },
    resetHeader: () => {
        set({ 
            title: false,
            help: ""
        })
    },
}));

export const useLoadingStore = create(() => ({ isLoading: false }));