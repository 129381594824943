import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { deDE } from "@mui/x-data-grid/locales";
import { useAlertMessageStore, useHeaderStore } from "src/store/store";
import { Box } from "@mui/material";
import MatchDataService from "src/services/match.service";
import Loading from "src/components/Loading";

const columns = [
  { field: "date", headerName: "Datum", type: "date", width: 150 },
  { field: "maingame", headerName: "Hauptspiel", width: 250 },
  { field: "extensions", headerName: "Erweiterungen", width: 300 },
  { field: "players", headerName: "Spieler", width: 300 },
  { field: "winners", headerName: "Gewinner", width: 300 },
  { field: "comment", headerName: "Anmerkung", width: 300 },
];

const sortModel = [
  {
    field: "date",
    sort: "desc",
  },
];

const PlayHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [playList, setPlayList] = useState([]);
  const collection_id = localStorage.getItem("collectionID");

  // Setting title and help using store
  useHeaderStore.setState({
    title: "Partieverlauf",
    help: "playHistory",
  });

  useEffect(() => {
    const fetchPlayList = async () => {
      setIsLoading(true);
      try {
        const response = await MatchDataService.getAll(collection_id);
        const data = response.data.data.map((val) => ({
          ...val,
          date: val.date ? new Date(val.date) : null,
        }));
        setPlayList(data);
      } catch (error) {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Lades der Partien",
          },
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlayList();
  }, [collection_id]);

  return (
    <Box sx={{ height: "80vh" }}>
      {isLoading && <Loading />}
      <DataGrid
        initialState={{ sortModel: sortModel }}
        rows={playList}
        columns={columns}
        checkboxSelection
        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        slots={{
          toolbar: GridToolbar,
        }}
        loading={isLoading}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: {
              delimiter: ";",
            },
          },
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
        }}
      />
    </Box>
  );
};

export default PlayHistory;
