import http from "../http-common";

class ClassPermissionDataService {
  getAll(collection_id) {
    return http.get(`/permissions?c=${collection_id}`);
  }

  getAllUsers(collection_id) {
    return http.get(`/permissions?c=${collection_id}&a=users`);
  }

  get(id, collection_id) {
    return http.get(`permissions/${id}?c=${collection_id}`);
  }

  create(data) {
    return http.post(`/permissions`, data);
  }

  update(id, data) {
    return http.put(`permissions/${id}`, data);
  }

  delete(username, collection_id) {
    return http.delete(`/permissions/${username}?c=${collection_id}`);
  }

  deleteAll(collection_id) {
    return http.delete(`/permissions?c=${collection_id}`);
  }

}

const PermissionDataService = new ClassPermissionDataService();
export default PermissionDataService;
