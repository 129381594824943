import { Link } from "react-router-dom";
import { East } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AlarmIcon from "@mui/icons-material/Alarm";
import GroupIcon from "@mui/icons-material/Group";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Avatar, Box, Card, Grid, IconButton } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { FlexBetween, FlexBox } from "src/components/flex-box";
import { H4, Span } from "src/components/Typography";
import { AutoTextSize } from "auto-text-size";

// styled components
const ContentWrapper = styled(Box)(({ theme, img }) => ({
  color: "white",
  backgroundSize: "cover",
  padding: "17px 30px 56px",
  backgroundPosition: "center",
  backgroundImage: `linear-gradient(to bottom,
    ${alpha(theme.palette.grey[600], 0.8)}, ${alpha(
    theme.palette.grey[900],
    0.8
  )}), 
    url('${img || "/assets/images/banners/cycle.png"}')`,
}));
const DiscoverGameCard = (props) => {
  const {
    name,
    id,
    minAge,
    playtime,
    minPlayers,
    maxPlayers,
    yearpublished,
    profilePicture,
  } = props;
  return (
    <Card>
      <Link to={`/discover/${id}`}>
        <ContentWrapper img={profilePicture}>
          <H4 fontWeight="600" mb={1}>
            <AutoTextSize mode="oneline" maxFontSizePx={17}>
              {name}
            </AutoTextSize>
          </H4>

          <Grid container>
            <Grid item xs={6}>
              <FlexBox gap={1}>
                <GroupIcon
                  fontSize="small"
                  sx={{
                    fontSize: 17,
                    mt: "3px",
                  }}
                />
                <Span color="white">
                  {!minPlayers && !maxPlayers
                    ? "n/A"
                    : minPlayers && !maxPlayers
                    ? minPlayers
                    : !minPlayers && maxPlayers
                    ? maxPlayers
                    : minPlayers + " - " + maxPlayers}
                </Span>
              </FlexBox>
            </Grid>

            <Grid item xs={6}>
              <FlexBox gap={1}>
                <AlarmIcon
                  fontSize="small"
                  sx={{
                    fontSize: 17,
                    mt: "3px",
                  }}
                />
                <Span color="white">
                  {playtime ? playtime + " Min" : "n/A"}
                </Span>
              </FlexBox>
            </Grid>

            <Grid item xs={6}>
              <FlexBox gap={1}>
                <SupervisorAccountIcon
                  fontSize="small"
                  sx={{
                    fontSize: 17,
                    mt: "3px",
                  }}
                />
                <Span color="white">{minAge ? minAge + "+" : "n/A"}</Span>
              </FlexBox>
            </Grid>

            <Grid item xs={6}>
              <FlexBox gap={1}>
                <CalendarMonthIcon
                  fontSize="small"
                  sx={{
                    fontSize: 17,
                    mt: "3px",
                  }}
                />
                <Span color="white">
                  {yearpublished ? yearpublished : "n/A"}
                </Span>
              </FlexBox>
            </Grid>
          </Grid>
        </ContentWrapper>

        <FlexBetween pl={3} pr={1}>
          <Avatar
            src={profilePicture || "missing"}
            alt={name}
            sx={{
              width: 64,
              height: 64,
              mt: "-32px",
              border: "3px solid",
              borderColor: "grey.100",
            }}
          />
          <IconButton
            sx={{
              my: 0.5,
            }}
          >
            <East
              sx={{
                fontSize: 19,
                transform: ({ direction }) =>
                  `rotate(${direction === "rtl" ? "180deg" : "0deg"})`,
              }}
            />
          </IconButton>
        </FlexBetween>
      </Link>
    </Card>
  );
};
export default DiscoverGameCard;
