import http from "../http-common";

class ClassBGDBDataService {
  searchGame({query, params}) {
    console.dir(query, params)
    return http.get(`/bgdb/search?${query}&${params ?? ""}`);
  }

  discover() {
    return http.get(`/bgdb/discover`);
  }
}

const BGDBDataService = new ClassBGDBDataService();
export default BGDBDataService;
