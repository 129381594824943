import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CssBaseline,
  FormControl,
  FormHelperText,
  Button,
  TextField,
  Grid,
  Autocomplete,
} from "@mui/material";
import Loading from "../Loading";

const validationSchema = Yup.object().shape({
  game: Yup.object().nullable().required("Bitte ein Spiel auswählen"),
  loan_to: Yup.object().nullable().required("Bitte einen Spieler eingeben"),
  loan_start: Yup.date().required("Bitte ein Startdatum eingeben"),
});

const LoanForm = ({
  initialValues,
  onSubmit,
  allPlayers,
  isSubmitting,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        setFieldValue,
      }) => (
        <>
          <CssBaseline />
          {isSubmitting && <Loading />}
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                  <TextField
                    id="game"
                    label="Spiel"
                    name="game"
                    disabled
                    value={values.game?.name}
                    onBlur={handleBlur}
                    error={Boolean(touched.game && errors.game)}
                    variant="standard" />
                  <FormHelperText error={Boolean(touched.game && errors.game)}>
                    {touched.game && errors.game ? errors.game : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(touched.loan_to && errors.loan_to)}
                >
                  <Autocomplete
                    name="loan_to"
                    id="loan_to"
                    value={values.loan_to}
                    options={allPlayers}
                    getOptionLabel={(option) => option.player_name}
                    isOptionEqualToValue={(option, value) => option.player_id === value.player_id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Verliehen an"
                        inputProps={{
                          ...params.inputProps,
                          "aria-autocomplete": "none",
                        }}
                        variant="standard"
                      />
                    )}
                    onChange={(e, value) => setFieldValue("loan_to", value)}
                    onBlur={handleBlur}
                    error={Boolean(touched.loan_to && errors.loan_to)}
                  />
                  <FormHelperText
                    error={Boolean(touched.loan_to && errors.loan_to)}
                  >
                    {touched.loan_to && errors.loan_to ? errors.loan_to : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                  <TextField
                    type="date"
                    id="loan_start"
                    name="loan_start"
                    label="Startdatum"
                    value={values.loan_start}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                  <FormHelperText
                    error={Boolean(touched.loan_start && errors.loan_start)}
                  >
                    {touched.loan_start && errors.loan_start
                      ? errors.loan_start
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                  <TextField
                    type="date"
                    id="loan_end"
                    name="loan_end"
                    label="Enddatum"
                    value={values.loan_end}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                  <FormHelperText
                    error={Boolean(touched.loan_end && errors.loan_end)}
                  >
                    {touched.loan_end && errors.loan_end ? errors.loan_end : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                  <TextField
                    id="description"
                    label="Anmerkung"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.description && errors.description)}
                    variant="standard"
                  />
                  <FormHelperText
                    error={Boolean(touched.description && errors.description)}
                  >
                    {touched.description && errors.description
                      ? errors.description
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValid || isSubmitting}
                  sx={{
                    display: "block",
                    margin: "auto",
                  }}
                >
                  Speichern
                </Button>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default LoanForm;
