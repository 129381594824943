import * as React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MemberShipPriceBox from "../MemberShipPriceBox";
import { Box, Button, Card, Typography } from '@mui/material';

export default function MemberShipPlanCard(props) {
  const { account, membershipPlan, handleUpgradeAccount } = props;

  return (
    <Card variant="elevation" sx={{
      color: "#212B36",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms ",
      background:
        "linear-gradient(to bottom, rgba(145, 158, 171, 0), rgba(145, 158, 171, 0.12))",
      overflow: "hidden",
      borderRadius: "16px",
      padding: "40px",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    }}>
      { account.type === membershipPlan.type && 
    <Typography sx={{ color: "green" }}>Aktiv</Typography>
      }
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h5" gutterBottom>
        {membershipPlan.type}
      </Typography>
      <MemberShipPriceBox price={membershipPlan.price} offer={membershipPlan.offer} />
    </Box>
    <Box sx={{
    width: "100%",
    maxWidth: 450,    
    paddingLeft: "24px",
    paddingRight: "24px",
    }}>
      <Box sx={{ display: "flex" }}>
        <CheckCircleOutlineIcon
          fontSize="small"
          color="primary"
        />
        <Typography
          variant="body2"
          sx={{
            marginLeft: "12px",
          }}
        >
          {membershipPlan.max_collections ? membershipPlan.max_collections : 'Unbegrenzte'} {membershipPlan.max_collection === 1 ? 'Sammlung' : 'Sammlungen'}
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <CheckCircleOutlineIcon
          fontSize="small"
          color="primary"
        />
        <Typography
          variant="body2"
          sx={{
            marginLeft: "12px"
          }}
        >
          {membershipPlan.max_games ? membershipPlan.max_games : 'Unbegrenzte'} {membershipPlan.max_games === 1 ? 'Spiel' : 'Spiele'}
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
      <CheckCircleOutlineIcon fontSize="small" color="primary" />
      <Typography variant="body2" sx={{marginLeft: "12px"}}>
        Unbegrenzte Partien
      </Typography>
    </Box>
    <Box sx={{ display: "flex" }}>
      <CheckCircleOutlineIcon fontSize="small" color="primary" />
      <Typography variant="body2" sx={{marginLeft: "12px"}}>
        Voller Funktionsumfang
      </Typography>
    </Box>
    <Box sx={{ display: "flex" }}>
      <CheckCircleOutlineIcon fontSize="small" color="primary" />
      <Typography variant="body2" sx={{marginLeft: "12px"}}>
        Werbe- und Cookiefrei
      </Typography>
    </Box>
    { (membershipPlan.level > 0 && membershipPlan.level >= account.level) &&
    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={()=>handleUpgradeAccount(membershipPlan)}
                        sx={{
                          marginTop: '24px'
                        }}
                      >
                        {membershipPlan.level === account.level ? "Verlängern" : "Upgraden"}
                      </Button>
                    </Box>
                    }
    </Box>                    
  </Card>
  );
}
