import React, { Component } from "react";
import { Formik } from "formik";
import { object, string } from "yup";
import withStyles from "@mui/styles/withStyles";
import OrderDataService from "../services/order.service";
import CouponDialog from "./CouponDialog";

import {
  FormControl,
  FormHelperText,
  Button,
  Container,
  TextField,
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material';

const useStyles = (theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    display: "block",
    margin: "auto",
    marginTop: "16px",
  },
});

class RedeemCoupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codeSuccess: false,
      couponPopup: false,
      coupon: {},
    };
  }

  _handleSubmit = ({ coupon, setSubmitting, resetForm, setErrors }) => {
    var data = {
      coupon: coupon,
    };
    OrderDataService.checkCoupon(data)
      .then((response) => {
        this.setState({
          codeSuccess: true,
          coupon: response.data,
          couponPopup: true,
        });
      })
      .catch((e) => {
        setErrors({ coupon: "Dieser Coupon ist leider ungültig." });
      });
    setSubmitting(false);
  };

  handleDialogClose = () => {
    this.setState({
        couponPopup: false
    });
};

  render() {
    const { classes, t } = this.props;
    const { codeSuccess, coupon, couponPopup } = this.state;
    return (
      <Formik
        initialValues={{
          coupon: "",
        }}
        validationSchema={object().shape({
          coupon: string().required("Bitte einen Coupon eingeben."),
        })}
        onSubmit={({ coupon }, { setSubmitting, resetForm, setErrors }) =>
          this._handleSubmit({
            coupon,
            setSubmitting,
            resetForm,
            setErrors,
          })
        }
        render={(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props;
          return (
            <>
              {(codeSuccess && coupon && couponPopup ) && (
                <CouponDialog
                  coupon={coupon}
                  handleDialogClose={this.handleDialogClose}
                  setStateValue={this.props.setStateValue}
                  history={this.props.history}
                />
              )}
              {isSubmitting && (
                <Backdrop className={classes.backdrop} open sx={{ zIndex: 1 }}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              <Container maxWidth="xs" sx={{ marginTop: "-35px" }}>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControl fullWidth margin="normal" variant="standard">
                      <TextField
                        id="coupon"
                        name="coupon"
                        label="Coupon"
                        variant="outlined"
                        value={values.coupon}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.coupon && errors.coupon)}
                      />
                      <FormHelperText
                        error={Boolean(touched.coupon && errors.coupon)}
                      >
                        {touched.coupon && errors.coupon ? errors.coupon : ""}
                      </FormHelperText>
                    </FormControl>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.selectButton}
                    >
                      Einlösen
                    </Button>
                  </Box>
                </form>
              </Container>
            </>
          );
        }}
      />
    );
  }
}

export default withStyles(useStyles)(RedeemCoupon);
