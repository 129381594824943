import http from "../http-common";

class ClassPlayerGroupDataService {
  getAll(collection_id) {
    return http.get(`/player-groups?c=${collection_id}`);
  }

  get(id, collection_id) {
    return http.get(`player-groups/${id}?c=${collection_id}`);
  }

  create(data) {
    return http.post(`/player-groups`, data);
  }

  update(id, data) {
    return http.put(`player-groups/${id}`, data);
  }

  delete(id, collection_id) {
    return http.delete(`/player-groups/${id}?c=${collection_id}`);
  }

}

const PlayerGroupDataService = new ClassPlayerGroupDataService();
export default PlayerGroupDataService;
