import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import { object, ref, string } from "yup";
import { authenticationService } from "../../auth/authentication.service";
import UserDataService from "../../services/user.service";
import { useAlertMessageStore } from "src/store/store";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  TextField,
} from "@mui/material";

import Copyright from "src/components/copyright";
import { FeatureGrid } from "src/components/feature";

const ForgotPassword = () => {
  const history = useHistory();
  const scrollToRef = useRef(null);
  useEffect(() => {
    if (authenticationService.currentUserValue) {
      history.push("/");
    }
  }, [history]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      username: values.username,
    };
    UserDataService.forgotPassword(data)
      .then((response) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message:
              "E-Mail zum Ändern des Passwortes wurde erfolgreich verschickt",
          },
        });
        history.push("/");
      })
      .catch((error) => {
        setSubmitting(false);
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Zurücksetzen des Passworts",
          },
        });
      });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <FeatureGrid scrollToRef={scrollToRef} />
      <Grid
        item
        xs={12}
        md={5}
        component={Paper}
        elevation={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: {
            xs: "100vw",
          },
          minHeight: {
            xs: "100vh",
          },
          pb: {
            xs: 1,
          },
          order: {
            xs: 1,
            md: "initial",
          },
        }}
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Formik
            initialValues={{
              username: "",
            }}
            validationSchema={object().shape({
              username: string().required("Bitte einen Benutzernamen eingeben"),
            })}
            onSubmit={handleSubmit}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              isSubmitting,
            }) => (
              <Container component="main" maxWidth="xs">
                {isSubmitting && (
                  <Backdrop sx={{ color: "#fff" }} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
                <Form
                  style={{ width: "100%", mt: "8px" }}
                  onSubmit={handleSubmit}
                >
                  <FormControl fullWidth margin="normal" variant="standard">
                    <TextField
                      id="username"
                      label="Benutzername"
                      name="username"
                      variant="outlined"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.username && errors.username)}
                    />
                    <FormHelperText
                      error={Boolean(touched.username && errors.username)}
                    >
                      {touched.username && errors.username
                        ? errors.username
                        : ""}
                    </FormHelperText>
                  </FormControl>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!isValid || isSubmitting}
                    sx={{ margin: "24px 0px 16px" }}
                  >
                    Neues Passwort anfordern
                  </Button>
                </Form>
                <Box mt={8}>
                  <Copyright />
                </Box>
              </Container>
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
