import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import PlayerDataService from "../../services/player.service";
import EventDataService from "../../services/event.service"
import CollectionDataService from "../../services/collection.service"



import { Grid, TextField, Button } from '@mui/material';



const useStyles = theme => ({
  container: {
    textAlign: 'center',
  },
  submit: {
    display: 'block',
    margin: 'auto',
  },
});

class AddEvent extends Component {

  constructor(props) {
    super(props);    
    this.handleChange = this.handleChange.bind(this);
    this.addEvent = this.addEvent.bind(this);

    this.event_id = this.props.match.params.id

    this.props.setStateValue('isLoading', true)
    this.props.setStateValue('pageTitle', 'Event bearbeiten')
    this.props.setStateValue('help', false);

    this.state = {

      event: {
        title: "",
        location: "",
        description: "",
      },
      playerCount: 0,
      player: [],

      gameCount: 0,

      collections: [],
      allPlayers: [],

    }
  }

  componentDidMount() {
    this.retrieveEvent(this.event_id);
  }
  

  retrieveEvent(id) {
    EventDataService.getByID(id)
    .then(response => {
      this.setState({
        event: response.data.data,
      })
      this.props.setStateValue('isLoading', false)
    })

}

retrieveCollection() {
  CollectionDataService.getAll()
  .then(response => {
    this.setState({
      collections: response.data.data.owned
    })
  })
  .catch(err => {
    console.log(err)
  })
}


retrievePlayers() {
  PlayerDataService.getAll(this.collection_id)
    .then(response => {
      let account_player = response.data.data.account_player
      let players = response.data.data.players
      this.setState({
        allPlayers: [ ...account_player, ...players ]
      })
    })
    .catch(e => {
      console.log(e);
    });
}

handleChange(e) {
  this.setState({
    event: {
      ...this.state.event,
      [e.target.name]: e.target.value,
    },
  })
}

  addEvent() {
    var data = {
      title: this.state.title,
      location: this.state.location,
      date: this.state.date + " " + this.state.time,
      description: this.state.comment,
    }
      EventDataService.create(data)
      .then(response => {
        this.props.setStateValue('alertMessage', { severity: 'success', message: 'Das Event wurde erfolgreich bearbeitet', key: Math.random() })
        this.props.history.push(`/events`); 
      })
      .catch(e => {
        this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Bearbeiten des Events', key: Math.random() })
        console.log(e);
      });
   
  }
  
  render() {
    const { event } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
          <form> 
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="title"
                name="title"
                label="Titel"
                value={event.title}
                onChange={this.handleChange}
                fullWidth
                variant="standard" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="location"
                name="location"
                label="Ort"
                value={event.location}
                onChange={this.handleChange}
                fullWidth
                variant="standard" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                type ="date"
                id="date"
                name="date"
                label="Datum"
                value={event.date}
                onChange={this.handleChange}
                fullWidth
                required
                InputLabelProps={{ shrink: true, }}
                variant="standard" />              
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                type ="time"
                id="time"
                name="time"
                label="Uhrzeit"
                value={event.time}
                onChange={this.handleChange}
                fullWidth
                required
                InputLabelProps={{ shrink: true, }}
                variant="standard" />              
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="description"
                name="description"
                label="Beschreibung"
                value={event.description}
                onChange={this.handleChange}
                fullWidth
                variant="standard" />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button className={classes.submit} variant="contained" color="primary" onClick={this.addEvent}>Speichern</Button>
            </Grid>
          </Grid>
          </form>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(AddEvent)