import { BehaviorSubject } from "rxjs";
import { authHeader } from "../helpers/auth-header";
import SessionDataService from "../services/session.service";

const base_url = process.env.REACT_APP_BASE_URL;

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${base_url}/api/users/login`, requestOptions).then(
    (response) => {
      if (response.ok) {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          let userSubject = data.auth;
          userSubject = { ...userSubject, ...data.account };
          localStorage.setItem("currentUser", JSON.stringify(userSubject));
          localStorage.setItem("collectionID", data.session.collectionID);
          localStorage.setItem("session", data.session.sessionToken);
          currentUserSubject.next(userSubject);

          return data.auth;
        });
      } else {
        return Promise.reject(response);
      }
    }
  );
}

function logout() {
  const session_token = localStorage.getItem("session");
  if (session_token) {
    SessionDataService.delete(session_token).then(() => {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("collectionID");
      localStorage.removeItem("session");
      currentUserSubject.next();
      authHeader();
    });
  } else {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("collectionID");
    currentUserSubject.next();
    authHeader();
  }
}
