import React, { Component } from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  CssBaseline,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Button,
  TextField,
  Grid,
  Autocomplete,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import LoanDataService from "../../services/loan.service";
import GameDataService from "../../services/game.service";
import PlayerDataService from "../../services/player.service";
import { useHeaderStore } from "src/store/store";


const useStyles = theme => ({
    submit: {
      display: 'block',
      margin: 'auto',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })


class AddLend extends Component {
  constructor(props) {
    super(props);
    this.game_id = this.props.match.params.game_id;
    this.collection_id = localStorage.getItem("collectionID");

    this.props.setStateValue('isLoading', true)
    useHeaderStore.setState({ title: "Leihe erfassen", help: "addLoan"})

    this.state = {
      allPlayers: [],
      title: "",
      gameID: null,
      collectionID: null,
      loading: true,

      metaInfo: [],
    }
  }

  componentDidMount() {
    this.retrieveGameDetails(this.game_id);
    this.retrievePlayers();
  }

  retrieveGameDetails(id) {
    
    GameDataService.get(id, this.collection_id)
    .then(response => {
      this.setState({
        title: response.data.data.game.name,
        collectionID: response.data.data.game.collection_id,
        gameID: response.data.data.game.id,
        metaInfo: response.data.meta,
        loading: false,
      });
      this.props.setStateValue('isLoading', false)
    })
    .catch(e => {
      console.log(e);
    });
}

retrievePlayers() {
  PlayerDataService.getAll(this.collection_id)
    .then(response => {
      let players = []
      response.data.data.players.map((player) => (
        !player.owner &&
          players.push({player_id: player.player_id, player_name: player.player_name})
      ))
      this.setState({
        allPlayers: players
      })
    })
    .catch(e => {
      console.log(e);
    });
}

  _handleSubmit = ({
    game_id,
    collection_id,
    lend_start,
    description,
    lend_to,
    setSubmitting,
    setError,
    resetForm,
  }) => {
    var data = {
      gameID: game_id,
      collectionID: collection_id,
      dateStart: lend_start,
      comment: description,
      lendTo: lend_to.player_id,
    }
    LoanDataService.create(data)
    .then(response => {
      this.props.setStateValue('alertMessage', { severity: 'success', message: 'Die Leihe wurde efolgreich erfasst', key: Math.random() })
      this.props.history.push(`/games/${game_id}`); 
    })
    .catch(e => {
      setSubmitting(false)
      if(e.response.status === 403) {
        this.props.setStateValue('alertMessage', { severity: 'error', message: `error.${e.response.data.error.code}`, key: Math.random() })
        this.props.history.push(`/`); 
      } else {
        this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Erfassen der Leihe', key: Math.random() })
        console.log(e);
      }
    });
  }

  render() {
    const { classes } = this.props; 
    const { title, gameID, collectionID, allPlayers, metaInfo, loading } = this.state;
    if (!loading && (!metaInfo.privileges?.owner && !metaInfo.privileges?.lend_insert)) {
      this.props.setStateValue('alertMessage', { severity: 'error', message: `error.2012`, key: Math.random() })
      this.props.history.push(`/`); 
    }else {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          game_title: title,
          game_id: gameID,
          collection_id: collectionID,
          lend_to: null,
          description: null,
          lend_start: "",
        }}
        validationSchema={Yup.object().shape({
          lend_to: Yup.object().nullable().required('Bitte einen Spieler eingeben'),
          lend_start: Yup.date().required('Bitte ein Startdatum eingeben'),
        })}
        onSubmit={(
          { game_id,
            collection_id,
            lend_start,
            description,
            lend_to,},
          { setSubmitting, setStatus, setError, resetForm }
        ) =>
          this._handleSubmit({
            game_id,
            collection_id,
            lend_start,
            description,
            lend_to,
            setSubmitting,
            setStatus,
            resetForm,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
            setFieldValue,
          } = props
          return <>       
            <CssBaseline />
            { isSubmitting && 
              <Backdrop className={classes.backdrop} open>
                <CircularProgress color="inherit" />
               </Backdrop>
            }
              <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                  <TextField
                    id="game_title"
                    label="Titel"
                    name="game_title"
                    disabled
                    value={values.game_title}
                    onBlur={handleBlur}
                    error={Boolean(touched.game_title && errors.game_title)}
                    variant="standard" />
                  <FormHelperText
                    error={Boolean(touched.game_title && errors.game_title)}
                  >
                    {touched.game_title && errors.game_title
                      ? errors.game_title
                      : ''}
                  </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                  <TextField
                    type ="date"
                    id="lend_start"
                    name="lend_start"
                    label="Startdatum"
                    value={values.lend_start}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    InputLabelProps={{ shrink: true, }}
                    variant="standard" />    
                  <FormHelperText
                    error={Boolean(touched.lend_start && errors.lend_start)}
                  >
                    {touched.lend_start && errors.lend_start
                      ? errors.lend_start
                      : ''}
                  </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                  <TextField
                    id="description"
                    label="Anmerkung"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.description && errors.description)}
                    variant="standard" />
                  <FormHelperText
                    error={Boolean(touched.description && errors.description)}
                  >
                    {touched.description && errors.description
                      ? errors.description
                      : ''}
                  </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.lend_to && errors.lend_to)}
                  variant="standard">
                  <Autocomplete
                    name="lend_to"
                    id="lend_to"
                    value={values.lend_to}
                    options={allPlayers}
                    getOptionLabel={(option) => option.player_name}
                    renderInput={(params) => <TextField
                      {...params}
                      label="Verliehen an"
                      inputProps={{...params.inputProps, 'aria-autocomplete': 'none'}}
                      variant="standard" />}
                    onChange={(e, value) => { setFieldValue("lend_to", value) }}
                    onBlur={handleBlur}
                    error={Boolean(touched.lend_to && errors.lend_to)}
                  />
                  <FormHelperText
                    error={Boolean(touched.lend_to && errors.lend_to)}
                  >
                    {touched.lend_to && errors.lend_to ? errors.lend_to : ''}
                  </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  variant="contained" 
                  color="primary"
                  disabled={Boolean(!isValid || isSubmitting)}
                  className={classes.submit}
                >
                  {'Speichern'}
                </Button>
                </Grid>
              </Grid>
              </form>
          </>;
        
        }}
      />
    );
  }
  return (
    <></>
  )
}
}

export default withStyles(useStyles)(AddLend)