import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PublicAppHeader from "./Public/PublicAppHeader";
import { makeStyles, useTheme } from '@mui/styles';
import { Container } from '@mui/material';


const useStyles = makeStyles(theme => ({
    container: {
      [theme.breakpoints.up("md")]: {
        marginLeft: "240px",
        maxWidth: "calc(99.9% - 240px)",
      },
      [theme.breakpoints.up("xxxl")]: {
        marginLeft: "auto",
        maxWidth: "1920px",
      },
    },
    main: {
      marginTop: "64px",
      padding: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      /*[theme.breakpoints.between('md','xxl')]: {
          paddingLeft: '240px'
        },*/
    },
  }));

export const PublicRoute = ({ component: Component, children, ...rest }) => {
    const classes = useStyles();
    
    return (
    <Route {...rest} render={props => { 

        // authorised so return component
        return <>
        <PublicAppHeader />
        <Container className={classes.container} >
            <main className={classes.main}>
                {children}
            </main>
        </Container>
        </>
    }} />
    )
}