import http from "../http-common";

class ClassNotificationDataService {
  subscribePush(data){
    return http.post(`notification/subscribe/push`, data);
  }

  unsubscribePush(data){
    return http.post(`notification/unsubscribe/push`, data);
  }

  subscribeMail(data){
    return http.post(`notification/subscribe/mail`, data);
  }

  unsubscribeMail(data){
    return http.post(`notification/unsubscribe/mail`, data);
  }

  subscribeNotification(action){
    return http.post(`notification/subscribe/notification?action=${action}`);
  }

  unsubscribeNotification(action){
    return http.post(`notification/unsubscribe/notification?action=${action}`);
  }

  getSubscriptionSettings(){
    return http.get(`notification/settings`)
  }

  getUnreadNotifications(){
    return http.post('notification/unread')
  }
}

const NotificationDataService = new ClassNotificationDataService();
export default NotificationDataService;
