import http from "../http-common";


class ClassStatisticDataService {
  
  findByCollection(collection_id) {
    return http.get(`/statistics/collection?c=${collection_id}`)
  }
  
  findByPlayer(collection_id, player_id) {
    return http.get(`/statistics/player?c=${collection_id}&p=${player_id}`)
  }
  
  findByAccount() {
    return http.get(`/statistics/account`)
  }
}

const StatisticDataService = new ClassStatisticDataService();
export default StatisticDataService;
