import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { createFilterOptions } from "@mui/material/Autocomplete";
import MatchDataService from "../../services/match.service";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useHeaderStore } from "src/store/store";

import {
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Divider,
  Autocomplete,
} from "@mui/material";

const useStyles = (theme) => ({
  container: {
    textAlign: "center",
  },
  submit: {
    display: "block",
    margin: "auto",
  },
  playerDivider: {
    [theme.breakpoints.up("xs")]: {
      width: "calc(100% - 16px)",
    },
  },
});

class EditMatch extends Component {
  constructor(props) {
    super(props);
    this.onChangeDateMatch = this.onChangeDateMatch.bind(this);
    this.onChangeCommentMatch = this.onChangeCommentMatch.bind(this);
    this.onChangePlayerCount = this.onChangePlayerCount.bind(this);
    this.editMatch = this.editMatch.bind(this);

    this.collection_id = localStorage.getItem("collectionID");

    this.match_id = this.props.match.params.match_id;
    this.game_id = null;

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({
      title: "Partie bearbeiten",
      help: "modifyPlay",
    });

    this.state = {
      name: "",
      commentMatch: "",
      dateMatch: null,
      recordPoints: false,

      playerCount: 0,
      player: [],

      selectedExtensions: [],

      allPlayers: [],
      allExtensions: [],

      dateError: "",
    };
  }

  componentDidMount() {
    this.retrieveMatchDetails(this.match_id);
  }

  retrieveMatchDetails(id) {
    MatchDataService.get(id)
      .then((response) => {
        this.game_id = response.data.match.game.id;
        var ids = new Set(
          response.data.match.players.map(({ player_id }) => player_id)
        );
        let allPlayers = response.data.players;
        allPlayers = allPlayers.filter(({ player_id }) => !ids.has(player_id));
        let recordPoints = response.data.match.game.recordPoints;
        response.data.match.expansions.map((expansion) => {
          recordPoints = recordPoints + expansion.recordPoints;
        });

        this.setState({
          name: response.data.match.game.name,
          commentMatch: response.data.match.comment,
          dateMatch: response.data.match.date,
          maingame_recordPoints: response.data.match.game.recordPoints,
          recordPoints: recordPoints > 0 ? 1 : 0,

          selectedExtensions: response.data.match.expansions,

          player: response.data.match.players,
          playerCount: response.data.match.players.length,

          allPlayers: allPlayers,
          allExtensions: response.data.expansions,
        });

        //this.retrievePlayers(ids);
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        console.dir(e);
        if (e.response.status === 403) {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: `error.${e.response.data.error.code}`,
            key: Math.random(),
          });
          this.props.history.push(`/`);
        }
      });
  }

  /*retrievePlayers(ids) {
  PlayerDataService.getAll(this.collection_id)
    .then(response => {
      let account_player = response.data.data.players.account_player
      let players = response.data.data.players.players
      let allPlayers = [ ...account_player, ...players ]
      allPlayers = allPlayers.filter(({ id }) => !ids.has(id));
      this.setState({
        allPlayers: allPlayers
      })
    })
    .catch(e => {
      console.log(e);
    });
}*/

  sortFunction(a, b) {
    if (a["player_name"] === b["player_name"]) {
      return 0;
    } else {
      return a["player_name"] < b["player_name"] ? -1 : 1;
    }
  }

  onChangeDateMatch(e) {
    this.setState({
      dateMatch: e.target.value,
    });
  }

  onChangeCommentMatch(e) {
    this.setState({
      commentMatch: e.target.value,
    });
  }

  onChangePlayer(value, index) {
    if (value) {
      this.setState({
        player: [
          ...this.state.player.slice(0, index),
          {
            ...value,
            winner: false,
            points: null,
          },
          ...this.state.player.slice(index + 1),
        ],
      });
      for (var i = 0; i <= this.state.allPlayers.length - 1; i++) {
        if (this.state.allPlayers[i]["player_id"] == value.player_id) {
          this.state.allPlayers.splice(i--, 1);
        }
      }
    } else {
      var playerDetails = this.state.player[index];
      this.setState({
        player: [
          ...this.state.player.slice(0, index),
          {
            player_id: "",
            player_name: "",
            username: null,
            winner: false,
            points: null,
          },
          ...this.state.player.slice(index + 1),
        ],
      });
      this.state.allPlayers.push(playerDetails);
      this.state.allPlayers.sort(this.sortFunction);
    }
  }

  onChangeWinner(value, index) {
    this.setState({
      player: [
        ...this.state.player.slice(0, index),
        {
          ...this.state.player[index],
          winner: value,
        },
        ...this.state.player.slice(index + 1),
      ],
    });
  }

  onChangePoints(event, index) {
    this.setState({
      player: [
        ...this.state.player.slice(0, index),
        {
          ...this.state.player[index],
          points: event.target.value,
        },
        ...this.state.player.slice(index + 1),
      ],
    });
  }

  onChangeExtensions(value) {
    let recordPoints = this.state.maingame_recordPoints;
    value.length > 0 &&
      value.map((extension, value) => {
        recordPoints = recordPoints + extension.recordPoints;
      });
    console.dir(recordPoints);
    this.setState({
      selectedExtensions: value,
      recordPoints: recordPoints > 0 ? 1 : 0,
    });
  }

  onChangePlayerCount() {
    this.setState({
      playerCount: this.state.playerCount + 1,
      player: [
        ...this.state.player.slice(0, this.state.playerCount),
        {
          player_id: "",
          player_name: "",
          winner: false,
        },
        ...this.state.player.slice(this.state.playerCount + 1),
      ],
    });
  }

  editMatch() {
    var data = {
      gameID: this.game_id,
      dateMatch: this.state.dateMatch,
      commentMatch: this.state.commentMatch,
      players: this.state.player,
      extensions: this.state.selectedExtensions,
    };

    if (data.dateMatch !== null && data.dateMatch !== "") {
      MatchDataService.update(this.match_id, data)
        .then((response) => {
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Die Partie wurde erfolgreich bearbeitet",
            key: Math.random(),
          });
          this.props.history.push(`/games/${this.game_id}`);
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.props.setStateValue("alertMessage", {
              severity: "error",
              message: `error.${e.response.data.error.code}`,
              key: Math.random(),
            });
            this.props.history.push(`/`);
          } else {
            this.props.setStateValue("alertMessage", {
              severity: "error",
              message: "Fehler beim Bearbeiten der Partie",
              key: Math.random(),
            });
            console.log(e);
          }
        });
    } else {
      this.props.setStateValue("alertMessage", {
        severity: "error",
        message: "Fehler beim Bearbeiten der Partie",
        key: Math.random(),
      });
      this.setState({
        dateError: {
          status: true,
          message: "Das Datum darf nicht leer sein.",
        },
      });
    }
  }

  render() {
    const { allPlayers, playerCount, allExtensions, recordPoints } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="name"
                name="name"
                label="Hauptspiel"
                value={this.state.name}
                autoComplete="game-name"
                disabled
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="date"
                id="dateMatch"
                name="dateMatch"
                label="Datum"
                value={this.state.dateMatch}
                onChange={this.onChangeDateMatch}
                error={this.state.dateError.status}
                helperText={this.state.dateError.message}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            {allExtensions?.length > 0 && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  id="extensions"
                  options={allExtensions}
                  value={this.state.selectedExtensions}
                  onChange={(event, value) => this.onChangeExtensions(value)}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  getItemValue={(option) => option.id}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Gespielte Erweiterungen"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                id="comment"
                name="comment"
                label="Anmerkung"
                value={this.state.commentMatch}
                onChange={this.onChangeCommentMatch}
                autoComplete="off"
                fullWidth
                variant="standard"
              />
            </Grid>
            {!allExtensions.length > 0 && <Grid item xs={12} sm={6} />}
            {Array(playerCount)
              .fill(null)
              .map((value, index) => (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={recordPoints ? 12 : 6}
                    md={6}
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={recordPoints ? 12 : 9}
                      sm={recordPoints ? 6 : 9}
                      md={recordPoints ? 8 : 10}
                    >
                      <Autocomplete
                        key={"player-" + index}
                        options={allPlayers}
                        value={this.state.player[index] || null}
                        getOptionLabel={(option) => option.player_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Spieler ${index + 1}`}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "off",
                              "aria-autocomplete": "none",
                            }}
                            variant="standard"
                          />
                        )}
                        onChange={(event, value) =>
                          this.onChangePlayer(value, index)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.player_id === value.player_id
                        }
                        autoComplete="off"
                        fullWidth
                      />
                    </Grid>
                    {recordPoints === 1 && (
                      <Grid item xs={6} sm={4} md={2}>
                        <TextField
                          id={`points-${index + 1}`}
                          name={`points-${index + 1}`}
                          type="number"
                          label="Punkte"
                          value={this.state.player[index]?.points}
                          onChange={(event, value) =>
                            this.onChangePoints(event, index)
                          }
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={recordPoints ? 6 : 3}
                      sm={recordPoints ? 2 : 3}
                      md={recordPoints ? 2 : 2}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            id={`winner-${index + 1}`}
                            name={`winner-${index + 1}`}
                            checked={this.state.player[index]?.winner}
                            onChange={(event, value) =>
                              this.onChangeWinner(value, index)
                            }
                          />
                        }
                        labelPlacement="top"
                        label="Sieger"
                      />
                    </Grid>
                    <Divider
                      className={classes.playerDivider}
                      variant="middle"
                    />
                  </Grid>
                </>
              ))}
            <Grid item xs={12} sm={12}>
              <Button
                className={classes.submit}
                variant="contained"
                color="primary"
                onClick={this.onChangePlayerCount}
              >
                Spieler hinzufügen
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                className={classes.submit}
                variant="contained"
                color="primary"
                onClick={this.editMatch}
              >
                Speichern
              </Button>
            </Grid>
          </Grid>
        </form>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(EditMatch);
