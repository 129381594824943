import { differenceInCalendarDays, isValid } from "date-fns";
import {
  Box,
  CardActionArea,
  CardContent,
  Grid,
  List,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  StatisticCard,
  StatisticCardMedia,
  StatisticListItem,
} from "src/components/StyledComponents";
import TabPanel from "src/components/TabPanel";

const Overview = ({ value, index, statistic, t, ...other }) => {
  const last_played_days_ago = isValid(
    new Date(statistic?.last_played_game?.date) || null
  )
    ? differenceInCalendarDays(
        new Date(),
        new Date(statistic.last_played_game?.date)
      )
    : null;

  return (
    <TabPanel value={value} index={index} dir="ltr">
      <Typography variant="h5" align="center" gutterBottom>
        Übersicht
      </Typography>
      <StatisticCard raised>
        <CardContent sx={{ textAlign: "center" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: 14, fontWeight: "bold" }}
                color="text.secondary"
                gutterBottom
              >
                Sammlung
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <List>
                  <StatisticListItem disablePadding>
                    <ListItemText
                      primary={statistic?.cnt_games}
                      secondary={
                        statistic?.cnt_games === 1 ? "Spiel" : "Spiele"
                      }
                    />
                  </StatisticListItem>
                  <StatisticListItem disablePadding>
                    <ListItemText
                      primary={statistic?.cnt_maingames}
                      secondary={
                        statistic?.cnt_maingames === 1 ? "Hauptspiel" : "Hauptspiele"
                      }
                    />
                  </StatisticListItem>
                  <StatisticListItem disablePadding>
                    <ListItemText
                      primary={statistic?.cnt_extensions}
                      secondary={
                        statistic?.cnt_extensions === 1 ? "Erweiterung" : "Erweiterungen"
                      }
                    />
                  </StatisticListItem>
                </List>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                borderLeft: "1px solid",
                borderImage:
                  "linear-gradient(to bottom, transparent 16px, #ccc) 1",
              }}
            >
              <Box>
                <List>
                  <StatisticListItem disablePadding>
                    <ListItemText
                      primary={statistic?.cnt_matches}
                      secondary={
                        statistic?.cnt_matches === 1 ? "Partie" : "Partien"
                      }
                    />
                  </StatisticListItem>
                  <StatisticListItem disablePadding>
                    <ListItemText
                      primary={statistic?.cnt_lends}
                      secondary={
                        statistic?.cnt_lends === 1 ? "Leihe" : "Leihen"
                      }
                    />
                  </StatisticListItem>
                  <StatisticListItem disablePadding>
                    <ListItemText
                      primary={statistic?.cnt_lends_active}
                      secondary={
                        statistic?.cnt_lends === 1 ? "aktive Leihe" : "aktive Leihen"
                      }
                    />
                  </StatisticListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </StatisticCard>
      <StatisticCard raised>
        <CardContent sx={{ textAlign: "center" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: 14, fontWeight: "bold" }}
                color="text.secondary"
                gutterBottom
              >
                Partien der letzten 30 Tage
              </Typography>
            </Grid>
            {statistic?.games_last_month?.length > 0 ? (
              <>
                <Grid item xs={6}>
                  <Box>
                    <List sx={{ fontSize: 12 }}>
                      {statistic?.games_last_month.map((play) => {
                        return (
                          <StatisticListItem disablePadding>
                            <ListItemText
                              primary={play.cnt_plays}
                              secondary={play.name}
                            />
                          </StatisticListItem>
                        );
                      })}
                    </List>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    borderLeft: "1px solid",
                    borderImage:
                      "linear-gradient(to bottom, transparent 16px, #ccc) 1",
                  }}
                >
                  <Box>
                    <List sx={{ fontSize: 14 }}>
                      <StatisticListItem disablePadding>
                        <ListItemText
                          primary={statistic?.plays_last_month?.cnt_plays}
                          secondary={
                            statistic?.plays_last_month?.cnt_plays === 1 ? "Partie" : "Partien"
                          }
                        />
                      </StatisticListItem>
                      <StatisticListItem disablePadding>
                        <ListItemText
                          primary={statistic?.plays_last_month?.cnt_games}
                          secondary={
                            statistic?.plays_last_month?.cnt_games === 1 ? "Spiel" : "Spiele"
                          }
                        />
                      </StatisticListItem>
                      <StatisticListItem disablePadding>
                        <ListItemText
                          primary={statistic?.plays_last_month?.cnt_players}
                          secondary="Mitspieler"
                        />
                      </StatisticListItem>
                    </List>
                  </Box>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography>Noch keine Partie erfasst</Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </StatisticCard>
      <StatisticCard raised>
        <CardActionArea
          component={Link}
          to={`/games/${statistic?.last_played_game?.id}`}
        >
          <CardContent sx={{ textAlign: "center" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  color="text.secondary"
                  gutterBottom
                >
                  Zuletzt gespielt
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {Object.keys(statistic?.last_played_game || {}).length > 0 ? (
                    <>
                      <StatisticCardMedia
                        component="img"
                        image={statistic?.last_played_game?.image}
                        alt={statistic?.last_played_game?.name}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            {last_played_days_ago === 0
                              ? "Heute"
                              : last_played_days_ago === 1
                              ? "Gestern"
                              : `Vor ${last_played_days_ago} Tagen`}
                          </Typography>
                          <Typography
                            variant="subtitle"
                            color="text.secondary"
                            sx={{ fontWeight: "bold" }}
                          >
                            {statistic?.last_played_game?.name}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            {statistic?.last_played_game?.players?.length > 0 &&
                              statistic?.last_played_game?.players?.map(
                                (player, index) => {
                                  if (index > 0) {
                                    return ", " + player.name;
                                  } else {
                                    return player.name;
                                  }
                                }
                              )}
                          </Typography>
                        </CardContent>
                      </Box>
                    </>
                  ) : (
                    <CardContent>
                      <Typography>
                        <Typography>Noch keine Partie erfasst</Typography>
                      </Typography>
                    </CardContent>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </StatisticCard>
    </TabPanel>
  );
};
export default Overview;
