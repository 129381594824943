import React, { useEffect } from 'react'
import { useHeaderStore } from "src/store/store";

export default function PageNotFound(props) {
  const updateHeader = useHeaderStore(state => state.updateHeader);

  useEffect(() => {
    props.setStateValue('isLoading', false)
    updateHeader({title: "page.notFound", help: false});

   }, [])

  return (
    <div>
        Page not found.
    </div>
  );
}