import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import NotificationDataService from "../services/notification.service";
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { IconButton } from '@mui/material';

export const NotificationSubscription = ({
  initState,
  texts,
  component: Component,
  action,
  endpoint,
  ...props
}) => {
  const [subscription, setSubscription] = useState(null)

  const handleClick = () => {
    if (subscription) {
      unsubscribeUser(setSubscription, action)
    } else {
      subscribeUser(setSubscription, action)
    }
  }

  useEffect(() => {
    checkSubscription(setSubscription, endpoint, initState)
  }, [])

  useEffect(() => {
    initState = subscription ? true : false
  }, [initState, subscription])


  if (!('Notification' in window)) {
    return null
  }

  /*if (Notification.permission === 'denied') {
    return (
      <Component {...props} disabled={true}>
        {texts.notifications} {texts.blocked}
      </Component>
    )
  }*/

  return (
    <IconButton
    aria-label="push_subscription"
    color="inherit"
    onClick={handleClick}
    size="small">
    <SmartphoneIcon color={subscription ? 'success' : ''} /> 
  </IconButton>
    
  )
}

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4)
    // eslint-disable-next-line
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")
  
    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

function checkSubscription(setSubscription=setSubscription, endpoint, initState) {
  navigator.serviceWorker.ready.then(registration => {
    if (registration.pushManager) {
      registration.pushManager.getSubscription().then(subscription => {
        if (subscription) {
            if (endpoint === subscription.endpoint && initState) setSubscription(subscription);
        }
      })
    }
  })
}


function addSubscription(subscription, action) {
  NotificationDataService.subscribePush({push_endpoint: subscription, action})
}

function removeSubscription(subscription, action) {
  NotificationDataService.unsubscribePush({push_endpoint: subscription, action})
}

function subscribeUser(setSubscription, action) {
const convertedVapidKey = urlBase64ToUint8Array('BKILrdompjY2cOKIzVDfNNjUUMlseAIpOuVoz8gQhE52-v-6bvq0rIUJCTiP6gymWpt4UglZLxbjsr4U3Iow6F8')
  return navigator.serviceWorker.ready.then(registration => {
    registration.pushManager.subscribe({
        applicationServerKey: convertedVapidKey,
        userVisibleOnly: true,
      }).then(function(newSubscription) {
        addSubscription(newSubscription, action)
        setSubscription(newSubscription, action)
      }).catch(function(e) {
        if (Notification.permission !== 'granted') {
          console.log('Permission was not granted.')
        } else {
          console.error('An error ocurred during the subscription process.', e)
        }
      })
    })
}

function unsubscribeUser(setSubscription, action) {
  navigator.serviceWorker.ready.then(registration => {
    registration.pushManager
      .getSubscription()
      .then(sub => {
        if (sub) {
          const subscription = sub
          removeSubscription(subscription, action)
        }
      })
      .catch(error => {
        setSubscription(error)
        console.log('Error unsubscribing', error)
      })
      .then(res => {
        setSubscription(null)
      })
  })
}

NotificationSubscription.propTypes = {
  texts: PropTypes.object,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  endpoint: PropTypes.string,
  action: PropTypes.number,
  initState: PropTypes.bool,
}

NotificationSubscription.defaultProps = {
  initState: false,
  endpoint: "",
  action: null,
  texts: {
    notifications: 'Notifications',
    enable: 'Enable',
    disable: 'Disable',
    blocked: 'Blocked',
  },
  component: 'button',
}
