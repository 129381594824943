import React, { useState, useEffect } from "react";
import UserDataService from "../../../services/user.service";
import makeStyles from '@mui/styles/makeStyles';
import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";

import { Box, Card, Stack, Table, TableContainer, TableBody } from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import TableHeader from "src/components/data-table/TableHeader";
import TablePagination from "src/components/data-table/TablePagination";
import useMuiTable from "src/hooks/useMuiTable";
import OrderRow from "src/pages-sections/orders/OrderRow";

const useStyles = makeStyles((theme) => ({
  selectButton: {
    marginTop: theme.spacing(3),
  },
  box: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "repeat(1, 1fr)",
    [theme.breakpoints.up("md")]: {
      gap: "32px",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  card: {
    color: "#212B36",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms ",
    background:
      "linear-gradient(to bottom, rgba(145, 158, 171, 0), rgba(145, 158, 171, 0.12))",
    overflow: "hidden",
    borderRadius: "16px",
    padding: "40px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  cardHighlighted: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "64px",
      paddingBottom: "64px",
    },
  },
  contentText: {
    marginLeft: "12px",
  },
  contentPrice: {},
  mobile: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    width: "100%",
    maxWidth: 360,
    height: "calc(100vh - 112px)",
    borderRight: "1px solid #ccc",
  },
  contentBox: {
    width: "100%",
    maxWidth: 500,
    marginTop: "30px",
    [theme.breakpoints.up("lg")]: {
      maxWidth: 525,
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: 600,
    },
  },
  contentTable: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  tableHeaderRow: {
    '& th': {
      fontWeight: 'inherit',
    }
  }
}));

const tableHeading = [
  {
    id: "type",
    label: "Mitgliedschaft",
    align: "left",
  },
  {
    id: "validFrom",
    label: "Gültig von",
    align: "left",
  },
  {
    id: "validUntil",
    label: "Gültig bis",
    align: "left",
  },
  {
    id: "completedAt",
    label: "Abgeschlossen am",
    align: "left",
  },
];

export default function MembershipHistory(props) {
  
  const [history, setHistory] = useState({})
  const updateHeader = useHeaderStore(state => state.updateHeader);

  function retrieveAccountDetails() {
    UserDataService.checkAccountType("accountInfo", 0)
      .then((response) => {
        setHistory(response.data.hist_membership)
        props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    props.setStateValue('isLoading', true)
    updateHeader({title: "Mitgliedschaft", help: "membership"});
    retrieveAccountDetails()
   }, [])

    const filteredOrders = history.length > 0 ? history.map((item) => ({
      id: item.id,
      type: item.type,
      validFrom: item.valid_from,
      validUntil: item.valid_until,
      completedAt: item.completed_at
    })) : [];

    
    const {
    order,
    orderBy,
    selected,
    rowsPerPage,
    filteredList,
    handleChangePage,
    handleRequestSort,
  } = useMuiTable({
    listData: filteredOrders,
    defaultSort: "validFrom",
    defaultOrder: "desc",
  });

    return (
      <AccountDashboardLayout>
        <Scrollbar>
          <TableContainer
          >
            <Table>
              <TableHeader
                order={order}
                hideSelectBtn
                orderBy={orderBy}
                heading={tableHeading}
                numSelected={selected.length}
                rowCount={filteredList.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {filteredList.length > 0 && filteredList.map((order) => (
                  <OrderRow history={order} key={order.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Stack alignItems="center" my={4}>
          <TablePagination
            onChange={handleChangePage}
            count={Math.ceil(filteredOrders.length / rowsPerPage)}
          />
        </Stack>
      </AccountDashboardLayout>
    );
  }

