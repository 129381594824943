import http from "../http-common";

class ClassEventDataService {
  getAll() {
    return http.get(`/events`);
  }

  getByID(event_id) {
    return http.get(`/events/${event_id}`)
  }
  
  create(data) {
    return http.post(`/events`, data)
  }
}

const EventDataService = new ClassEventDataService();
export default EventDataService;
