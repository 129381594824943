import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom/";
import LocationDataService from "src/services/location.service";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AccountDashboardLayout from "src/components/layouts/account-dashboard";
import { useAlertMessageStore, useHeaderStore } from "src/store/store";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  TextField,
  Box,
} from "@mui/material";

const Locations = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [locationError, setLocationError] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [locations, setLocations] = useState([]);
  const [editLocation, setEditLocation] = useState(null);
  const [editLocationError, setEditLocationError] = useState("");
  const [privileges, setPrivileges] = useState({});
  const { collectionID } = useParams();

  useEffect(() => {
    useHeaderStore.setState({
      title: "Standorte",
      help: "locations",
    });
    retrieveLocations();
  }, []);

  const retrieveLocations = () => {
    LocationDataService.getAll(collectionID)
      .then((response) => {
        setLocations(response.data.locations);
        setPrivileges(response.data.meta?.privileges);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.dir(e);
        if (e.response?.status === 403) {
          history.push(`/`);
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message:
                "Du hast keine Berechtigungen um die Standorte dieser Sammlung zu sehen",
            },
          });
        } else {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message: "Fehler beim Laden der Standorte",
            },
          });
        }
      });
  };

  const addNewLocation = () => {
    if (newLocation.trim() === "") {
      setLocationError("Der Standort darf nicht leer sein");
      return;
    }

    const data = { locationName: newLocation, collectionID: collectionID };

    LocationDataService.create(data)
      .then(() => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: "Der Standort wurde erfolgreich hinzugefügt",
          },
        });
        setNewLocation("");
        retrieveLocations();
      })
      .catch((e) => {
        if (e.response?.status === 403) {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message:
                "Du hast keine Berechtigungen um Standorte in dieser Sammlung anzulegen",
            },
          });
          history.push(`/`);
        } else {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message: "Fehler beim Hinzufügen des Standortes",
            },
          });
        }
      });
  };

  const deleteLocation = (location_id) => {
    LocationDataService.delete(location_id, collectionID)
      .then(() => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: "Der Standort wurde erfolgreich gelöscht",
          },
        });
        retrieveLocations();
      })
      .catch((e) => {
        if (e.response?.status === 403) {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message:
                "Du hast keine Berechtigungen um Standorte in dieser Sammlung zu löschen",
            },
          });
          history.push(`/`);
        } else if (e.response?.status === 409) {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message: "Fehler beim Löschen des Standortes",
              errorList: ["Diesem Standort sind noch Spiele zugeordnet"]
            },
          });
        } else {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message: "Fehler beim Löschen des Standortes",
            },
          });
        }
      });
  };

  const saveLocation = () => {
    if (!editLocation || editLocation.name.trim() === "") {
      setEditLocationError("Der Standort darf nicht leer sein");
      return;
    }

    const data = {
      locationName: editLocation.name,
      collectionID: collectionID,
    };

    LocationDataService.update(editLocation.id, data)
      .then(() => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: "Der Standort wurde erfolgreich bearbeitet",
          },
        });
        setEditLocation(null);
        retrieveLocations();
      })
      .catch((e) => {
        if (e.response?.status === 403) {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message:
                "Du hast keine Berechtigungen um Standorte in dieser Sammlung zu bearbeiten",
            },
          });
          history.push(`/`);
        } else {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message: "Fehler beim Bearbeiten des Standortes",
            },
          });
        }
      });
  };

  const handleEditChange = (e) => {
    setEditLocation({ ...editLocation, name: e.target.value });
  };

  return (
    <AccountDashboardLayout isLoading={isLoading}>
      <Box sx={{ width: "100%", maxWidth: 450 }}>
        <List component="nav">
          {privileges.owner === 1 || privileges.collectiondetail_modify === 1
            ? locations.map((location) =>
                editLocation?.id === location.id ? (
                  <ListItem key={location.id}>
                    <TextField
                      value={editLocation.name}
                      onChange={handleEditChange}
                      error={!!editLocationError}
                      helperText={editLocationError}
                      fullWidth
                      variant="standard"
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={saveLocation}
                        size="large"
                      >
                        <CheckIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ) : (
                  <ListItem key={location.id}>
                    <ListItemText primary={location.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() => setEditLocation(location)}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                      {privileges.collectiondetail_delete === 1 && (
                        <IconButton
                          edge="end"
                          color="error"
                          onClick={() => deleteLocation(location.id)}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              )
            : locations.map((location) => (
                <ListItem key={location.id}>
                  <ListItemText primary={location.name} />
                </ListItem>
              ))}
          {privileges.collectiondetail_insert === 1 && (
            <>
              <Divider />
              <ListItem>
                <TextField
                  id="newLocation"
                  name="newLocation"
                  label="Neuer Standort"
                  value={newLocation}
                  onChange={(e) => setNewLocation(e.target.value)}
                  error={!!locationError}
                  helperText={locationError}
                  fullWidth
                  variant="standard"
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={addNewLocation} size="large">
                    <CheckIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </>
          )}
        </List>
      </Box>
    </AccountDashboardLayout>
  );
};

export default Locations;