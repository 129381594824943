import http from "../http-common";

class ClassUserDataService {
  getDetails(){
    return http.get(`/users/getDetails`);
  }
  updateSingle(data){
    return http.put(`/users/update`, data);
  }
  login(data) {
    return http.post(`/users/login`, data);
  }

  signup(data) {
    return http.post(`/users/signup`, data);
  }

  changePassword(data) {
    return http.put(`/users/changepassword`, data)
  }

  forgotPassword(data) {
    return http.post(`/users/forgotpassword`, data)
  }

  checkPasswordToken(user, token) {
    return http.get(`/users/checkpasswordtoken?user=${user}&token=${token}`);
  }

  checkAccountToken(user, token) {
    return http.get(`/users/checkaccounttoken?user=${user}&token=${token}`);
  }

  checkAccountType(action, id) {
    return http.get(`/users/checkaccounttype?action=${action}&id=${id}`)
  }

  resetPassword(data) {
    return http.put(`/users/resetpassword`, data)
  }
}

const UserDataService = new ClassUserDataService();
export default UserDataService;
