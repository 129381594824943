import http from "../http-common";

class ClassMatchDataService {
  getAll(collection_id) {
    return http.get(`/matches?c=${collection_id}`);
  }
   
  get(id) {
    return http.get(`/matches/${id}}`);
  }

  create(data) {
    return http.post(`/matches`, data);
  }

  update(id, data) {
    return http.put(`/matches/${id}`, data);
  }

  delete(id) {
    return http.delete(`/matches/${id}`);
  }

}

const MatchDataService = new ClassMatchDataService();
export default MatchDataService;
