import React, { Component } from "react";
import CollectionDataService from "../../../services/collection.service";
import withStyles from '@mui/styles/withStyles';
import PersonIcon from '@mui/icons-material/Person';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import AccountDashboardLayout from "src/components/layouts/account-dashboard";
import { useHeaderStore } from "src/store/store";

import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  IconButton,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Box,
} from '@mui/material';

const useStyles = theme => ({
  collectionIcon: {
    verticalAlign: 'bottom'
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  contentList: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
  contentBox: {
    width: '100%',
    maxWidth: 450,
  }
});

class Collections extends Component {

  constructor(props) {
    super(props);
    this.handleChangeSpeedDial = this.handleChangeSpeedDial.bind(this)
    this.collectionAdded = this.props.collectionAdded   
    this.collectionDeleted = this.props.collectionDeleted 

    useHeaderStore.setState({ title: "Sammlungen", help: "collections"})
    this.props.setStateValue('isLoading', true)

    this.state = {
      owned: [],
      access: [],

      openSpeedDial: false,
      linkList: []
    }
  }
 
  componentDidMount() {
    this.retrieveCollections();
  }  

retrieveCollections() {
  
  CollectionDataService.getAll()
    .then(response => {
      this.setState({
        owned: response.data.data.owned,
        access: response.data.data.access,
        linkList: response.data.data.owned
      })
      this.props.setStateValue('isLoading', false)
    })
}

handleChangeSpeedDial() {
  this.setState({
    openSpeedDial: !this.state.openSpeedDial
  })
}

render() {
  const { owned, access, openSpeedDial, linkList } = this.state
  const { classes } = this.props;

  return (
    <AccountDashboardLayout>
      <Box className={classes.contentBox}>
        <List component="nav" className={classes.contentList}>
          { owned?.length > 0 &&
          <ListSubheader>Eigene Sammlungen</ListSubheader>
          } 
          { owned.map((collection, index) => (
          <ListItem button component={Link} to={`/settings/collections/${collection.collection_id}`} key={`owned-${index}`}>
            <ListItemText primary={collection.collection_name} />
            <ListItemSecondaryAction>
              <IconButton edge="end" size="large" component={Link} to={`/settings/collections/${collection.collection_id}`}>
                <ChevronRightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          ))}
          <Divider />
          {access?.length > 0 &&
            <ListSubheader>Sammlungen anderer</ListSubheader>
          }
          { access.map((collection, index) => (
          <ListItem button component={Link} to={`/settings/collections/${collection.collection_id}`} key={`access-${index}`}>
            <ListItemText primary={collection.collection_name} secondary={<><PersonIcon className={classes.collectionIcon} /> {collection.collection_owner}</>} />
            <ListItemSecondaryAction>
              <IconButton edge="end" size="large"  component={Link} to={`/settings/collections/${collection.collection_id}`}>
                <ChevronRightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          ))}
        </List>
      </Box>
      <SpeedDial ariaLabel="Sammlungsmenü" className={classes.speedDial} icon={<SpeedDialIcon icon={<MoreVertIcon />} />} open={openSpeedDial} onOpen={this.handleChangeSpeedDial} onClose={this.handleChangeSpeedDial} >
        <SpeedDialAction key="add" icon={<AddIcon color="primary"/>} tooltipTitle="Sammlung hinzufügen" component={Link} to={`/settings/collections/add-collection`}/>
      </SpeedDial>      
    </AccountDashboardLayout>
  );
}
}

export default withStyles(useStyles)(Collections)