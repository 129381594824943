import http from "../http-common";

class ClassOrderDataService {
  createOrder(data){
    return http.post(`/orders/pay`, data);
  }

  captureOrder(orderID){
    return http.post(`/orders/${orderID}/capture`);
  }

  getMembershipPlans(){
    return http.get(`/orders/membershipplans`);
  }

  checkCoupon(data){
    return http.post(`/orders/checkCoupon`, data);
  }

  redeemCoupon(data){
    return http.post(`/orders/redeemCoupon`, data);
  }
}

const OrderDataService = new ClassOrderDataService();
export default OrderDataService;