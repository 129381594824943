const helpTexts = {
  archive: {
    title: "Archiv",
    body: "Hier findest du eine Übersicht deiner archivierten Spiele. Bei Bedarf kannst du diese wieder in deine aktive Sammlung verschieben."
},
collections: {
    title: "Sammlungen",
    body: "In dieser Übersicht siehst du die von dir erstellen Sammlungen sowie jene, auf denen du berechtigt bist. Eine Sammlung ist dabei die \"Klammer\" um deine Spiele, Standorte, Partien, Mitspieler und Leihen. Du kannst für jede Sammlung einzeln Berechtigungen vergeben und so anderen Spieler Lese- und Schreibrechte auf deiner Sammlung vergeben."
},
playHistory: {
    title: "Partieverlauf",
    body: "Hier findest du eine Übersicht über alle in deiner Sammlung erfassten Partien nach Datum sortiert. Du kannst sie nach verschiedenen Kriterien filtern und sortieren. Auch eine Kombination mehrerer Filter ist möglich."
},
loanHistory: {
    title: "Leihverlauf",
    body: "Hier findest du eine Übersicht über alle in deiner Sammlung erfassten Leihen nach Datum sortiert. Du kannst sie nach verschiedenen Kriterien filtern und sortieren. Auch eine Kombination mehrerer Filter ist möglich."
},
locations: {
    title: "Standorte",
    body: "In dieser Übersicht kannst du die Standorte für deine Spiele einsehen und bearbeiten. Standorte helfen dir dabei, deine Spiele wiederzufinden (z.B. Standort Dachboden, Kinderzimmer...)."
},
notifications: {
    title: "Benachrichtigungen",
    body: "In dieser Übersicht kannst du bequem Benachrichtigungen für unterschiedliche Ereignisse aktivieren und dabei auch entscheiden, auf welchem Weg du die Information erhalten möchtest. Dabei kannst du dich auch parallel auf mehreren Wegen informieren lassen."
},
permissions: {
    title: "Berechtigungen",
    body: "In dieser Übersicht kannst du die Berechtigungen für andere Spieleordner.de Benutzer auf deiner Sammlung verwalten. Dabei werden grundsätzlich 2 Benutzertypen unterschieden: Benutzer (dürfen sich alles anschauen, aber nichts selbst pflegen/editieren) und Editoren (dürfen auch Spiele, Partien, Leihen anlegen, pflegen bearbeiten etc.)"
},
players: {
    title: "Mitspieler",
    body: "In dieser Übersicht kannst du deine Mitspieler einsehen und bearbeiten. Mitspieler sind die Basis für die Erfassung von Partien und Leihen. Sollte der Mitspieler selbst auch einen Spieleordner.de Benutzer haben, kannst du zudem hier die Verbindung zwischen dem Mitspieler und seinem Spieleordner.de-Benutzeraccount herstellen."
},
playergroup: {
    title: "Spielergruppen",
    body: "Spielergruppen ermöglichen eine Gruppe von Spielern zu bilden. Dies erleichtert, zum Beispiel beim Erfassen einer Partie, die Auswahl der Spieler, da einfach eine Spielergruppe gewählt werden kann und automatisch alle Spieler dieser Gruppe der Partie hinzugefügt werden."
},
advancedSearch: {
    title: "Erweiterte Suche",
    body: "Hier findest du eine Übersicht über alle in deiner Sammlung erfassten Spiele. Du kannst sie nach verschiedenen Kriterien filtern und sortieren. Auch eine Kombination mehrerer Filter ist möglich."
},
sessions: {
    title: "Sitzungen",
    body: "In dieser Übersicht kannst du deine aktiven Sitzungen einsehen. Mehrere Sitzungen entstehen z.B. wenn du Spieleordner.de vom mehreren Endgeräten nutzt (Handy, Laptop etc.) Über das Symbol rechts kannst du die Sitzung an dem jeweiligen Endgerät beenden."
},
settings: {
    title: "Einstellungen",
    body: "In diesem Bereich kannst du diverse Einstellungen auf Basis deiner Wünsche anpassen."
},
statistic: {
    title: "Statistik",
    body: "Hier findest du einige statistische Auswertungen auf Basis deiner Sammlung."
},
statisticAccount: {
    title: "Meine Statistik",
    body: "Hier findest du einige statistische Auswertungen auf Basis deines Accounts."
}
};

export default helpTexts;