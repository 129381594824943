import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { authenticationService } from "src/auth/authentication.service";

const PrivateRoute2 = ({ children }) => {
  const currentUser = authenticationService.currentUserValue;
  const location = useLocation();

  if (!currentUser) {
    // User ist nicht eingeloggt, also Umleitung zur Login-Seite
    return (
      <Redirect to={{ pathname: "/login", state: { from: location } }} />
    );
  }

  // User ist eingeloggt, daher das übergebene Element rendern
  return children;
};

export default PrivateRoute2;
