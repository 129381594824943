import Menu from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Sidenav from "src/components/Sidenav";
import { FlexBox } from "src/components/flex-box";
import { useLocation } from "react-router-dom";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(-2),
  "& .headerHold": {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
  [theme.breakpoints.up("lg")]: {
    display: "none",
    "& .sidenav": {
      display: "none",
    },
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

// ==============================================================

// ==============================================================

const AccountDashboardHeader = ({ title, button, navigation, ...props }) => {
  
  const { pathname }  = useLocation();
  const checkRouteMatch = () => {
    return pathname === "/settings";
  };
  const currentRoute = checkRouteMatch();

  return (
    <StyledBox sx={{ display: currentRoute ? "none" : "flex" }}>
      <FlexBox mt={2} className="headerHold">
        <FlexBox alignItems="center">
          {props.icon && <props.icon color="primary" />}
            {title}
        </FlexBox>

        <Box className="sidenav">
          <Sidenav position="left" handle={<Menu fontSize="small" />}>
            {navigation}
          </Sidenav>
        </Box>

      </FlexBox>
    </StyledBox>
  );
};
export default AccountDashboardHeader;
