import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { authenticationService } from "../../auth/authentication.service";
import { useAlertMessageStore } from "src/store/store";
import { Formik, Field, Form } from "formik";
import { object, string } from "yup";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Paper,
} from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import Copyright from "src/components/copyright";
import { FeatureGrid } from "src/components/feature";

function LearnMore({ scrollToRef }) {
  return (
    <Box sx={{ display: "block", bottom: 0, top: "auto" }}>
      <Button
        variant="contained"
        color="primary"
        sx={{
          display: {
            md: "none",
          },
          borderRadius: "1rem",
          margin: "24px 0 16px 0",
        }}
        startIcon={<ArrowCircleDownIcon />}
        endIcon={<ArrowCircleDownIcon />}
        onClick={() =>
          scrollToRef.current.scrollIntoView({ behavior: "smooth" })
        }
      >
        Lerne Spieleordner kennen
      </Button>
    </Box>
  );
}

function SignIn() {
  const history = useHistory();
  const location = useLocation();
  const scrollToRef = useRef(null);

  useEffect(() => {
    if (authenticationService.currentUserValue) {
      const { pathname, search } = location;
      history.push({ pathname: pathname || "/", search: search || "" });
    }
  }, [location, history]);

  const handleSubmit = (values, { setSubmitting }) => {
    authenticationService
      .login(values.username, values.password)
      .then((response) => {
        const { pathname } = location || { pathname: "/" };
        history.push(pathname);
      })
      .catch((error) => {
        setSubmitting(false);
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: `Fehler bei der Anmeldung`,
          },
        });
      });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <FeatureGrid scrollToRef={scrollToRef} />
      <Grid
        item
        xs={12}
        md={5}
        component={Paper}
        elevation={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: {
            xs: "100vw",
          },
          minHeight: {
            xs: "100vh",
          },
          pb: {
            xs: 1,
          },
          order: {
            xs: 1,
            md: "initial",
          },
        }}
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="../logo192.png"
            alt="Spieleordner Logo"
            style={{ display: "block", margin: "auto" }}
          />
          <LearnMore scrollToRef={scrollToRef} />
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={object().shape({
              username: string().required("Bitte einen Benutzernamen eingeben"),
              password: string().required("Bitte ein Passwort eingeben"),
            })}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, isValid, touched, errors, handleSubmit }) => (
              <Box>
                {isSubmitting && (
                  <Backdrop sx={{ color: "#fff" }} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
                <Form
                  onSubmit={handleSubmit}
                  style={{ width: "100%", mt: "8px" }}
                >
                  <FormControl fullWidth margin="normal">
                    <Field
                      name="username"
                      as={TextField}
                      label="Benutzername"
                      variant="outlined"
                      error={touched.username && Boolean(errors.username)}
                      helperText={touched.username && errors.username}
                    />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <Field
                      name="password"
                      as={TextField}
                      type="password"
                      label="Passwort"
                      variant="outlined"
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!isValid || isSubmitting}
                    sx={{ margin: "24px 0px 16px" }}
                  >
                    Anmelden
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="/forgotpassword" variant="body2">
                        Passwort vergessen?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/signup" variant="body2">
                        Noch keinen Account? Registrieren
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              </Box>
            )}
          </Formik>

          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default SignIn;
