import http from "../http-common";

class ClassLocationDataService {
  getAll(collection_id) {
    return http.get(`/locations?c=${collection_id}`);
  }

  get(id, collection_id) {
    return http.get(`locations/${id}?c=${collection_id}`);
  }

  create(data) {
    return http.post(`/locations`, data);
  }

  update(id, data) {
    return http.put(`locations/${id}`, data);
  }

  delete(id, collection_id) {
    return http.delete(`/locations/${id}?c=${collection_id}`);
  }

  deleteAll(collection_id) {
    return http.delete(`/locations?c=${collection_id}`);
  }

}

const LocationDataService = new ClassLocationDataService();
export default LocationDataService;
