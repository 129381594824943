import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import OrderDataService from "../services/order.service";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import moment from "moment";

import {
  useTheme,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

export default function UpgradeDialog(props) {
  const { currAccount, membershipPlan, cancel, handleDialogClose, clientID } = props;

  return (
    <div>
      <Dialog
        open="open"
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {currAccount.type === membershipPlan.type
            ? `${membershipPlan.type}-Mitgliedschaft verlängern`
            : `Mitgliedschaft auf ${membershipPlan.type} upgraden`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {currAccount.type === membershipPlan.type
              ? `Durch den Abschluss wird die Laufzeit der ${membershipPlan.type}-Mitgliedschaft um ein Jahr verlängert`
              : `Durch den Abschluss der Transaktion werden alle Funktionen der ${membershipPlan.type}-Mitgliedschaft freigeschaltet.`}
            </DialogContentText>
            <DialogContentText id="alert-dialog-information">
            Laufzeit: Bis einschließlich{" "}<b>
            {currAccount.type === membershipPlan.type
              ? moment(currAccount.valid_until).add(1, "Y").format("DD.MM.YYYY")
              : moment().add(1, "Y").subtract(1, "d").format("DD.MM.YYYY")}
            </b><br />
            Preis: <b>{membershipPlan.price % 1 ? membershipPlan.price.replace('.', ',') : Math.trunc(membershipPlan.price)} €</b>
            </DialogContentText>
          <Container maxWidth="xs" sx={{ padding: "30px" }}>
            <PayPalScriptProvider
              options={{
                "client-id":
                  clientID,
                currency: "EUR",
              }}
            >
              <PayPalButtons
                style={{ shape: "pill", layout: "vertical" }}
                createOrder={(data, actions) => {
                  return OrderDataService.createOrder({accountType: membershipPlan.type})
                    .then((response) => response.data)
                    .then((order) => order.id);
                }}
                onApprove={(data, actions) => {
                  return OrderDataService.captureOrder(data.orderID)
                    .then((orderData) => {
                      props.setStateValue('alertMessage', { severity: 'success', message: `${orderData.data.message}`, key: Math.random() })
                      props.history.push('/settings/account/membership'); 
                    }
                  );
                }}
              />
            </PayPalScriptProvider>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="error">
            {cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
