import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom/";
import queryString from "query-string";
import CategoryDataService from "src/services/category.service";
import GameDataService from "src/services/game.service";

import {
  Box,
  Chip,
  Grid,
  LinearProgress,
  Link,
  Pagination,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";

import { GameCard } from "src/components/game";
import { GameCardSkeleton } from "src/components/skeleton";
import Loading from "src/components/Loading";
import { FlexBox } from "src/components/flex-box";
import {
  useAlertMessageStore,
  useGameSearchStore,
  useHeaderStore,
} from "src/store/store";

const Home = () => {
  const location = useLocation();
  const resultLimit = 40;

  const [queryParams, setQueryParams] = useState(
    queryString.parse(location.search)
  );
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [gameList, setGameList] = useState([]);
  const [metaInfo, setMetaInfo] = useState({});
  const [searchString, setSearchString] = useState(
    useGameSearchStore((state) => state.searchString)
  );
  const [searchCategory, setSearchCategory] = useState(
    useGameSearchStore((state) => state.categoryFilter)
  );
  const [collectionID, setCollectionID] = useState(
    localStorage.getItem("collectionID")
  );
  const [categoryList, setCategoryList] = useState([]);

  const handleChangeSpeedDial = () => {
    setOpenSpeedDial((prevOpen) => !prevOpen);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleCategoryFilterClick = useCallback(async (categoryID) => {
    setPage(1);
    setIsLoading(true);
    setSearchCategory(categoryID);
    useGameSearchStore.setState({ categoryFilter: categoryID });
  }, []);

  const fetchCategoryList = useCallback(async () => {
    await CategoryDataService.getAll()
      .then((response) => {
        setCategoryList(response.data);
      })
      .catch((error) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "warning",
            message: "Fehler beim Laden der Kategorien",
          },
        });
      });
  }, []);

  const fetchGameList = useCallback(async () => {
    await GameDataService.findByName(
      searchString,
      searchCategory,
      collectionID,
      page,
      resultLimit,
      queryParams.archived
    )
      .then((response) => {
        setGameList(response.data.data);
        setMetaInfo(response.data.meta);
      })
      .catch((error) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Laden der Spiele",
          },
        });
      });
    if (queryParams.archived) {
      useHeaderStore.setState({
        title: "Partie bearbeiten",
        help: "archive",
      });
    } else {
      
      useHeaderStore.setState({
        title: null,
        help: null,
      });
    }
  }, [searchString, searchCategory, collectionID, page, queryParams]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchGameList(), fetchCategoryList()]);
      } finally {
        setIsLoading(false);
        setIsInit(false);
      }
    };
    fetchData();
  }, [fetchGameList, fetchCategoryList]);

  useEffect(() => {
    if (isLoading) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [isLoading]);

  useEffect(() => {
    setPage(1);
    setCollectionID(localStorage.getItem("collectionID"));
    setQueryParams(queryString.parse(location.search));
  }, [location]);

  useEffect(() => {
    useHeaderStore.setState({ title: null, help: null });
    const gameSearchSub = useGameSearchStore.subscribe((gameSearch) => {
      setSearchString(gameSearch.searchString);
    });
    return () => {
      gameSearchSub();
    };
  }, []);

  if(isLoading) {
    return (
      <GameCardSkeleton />
    )
  }

  if (!collectionID || collectionID === "null") {
    return (
      <Box spacing={2}>
        <Typography>
          Du bist noch kein Mitglied einer Sammlung. Klicke{" "}
          <Link href="/settings/collections/add-collection">hier</Link> um eine
          neue Sammlung anzulegen.
        </Typography>
      </Box>
    );
  }


  return (
    <Box spacing={2}>
      {metaInfo.totalGames === 0 ? (
        queryParams.archived ? (
          <Box spacing={2}>
            <Typography>
              Du hast noch kein Spiel in deinem Archiv. Verschiebe ein Spiel
              indem du das Spiel bearbeitest und dann "Archivieren" wählst.
            </Typography>
          </Box>
        ) : (
          <Box spacing={2}>
            <Typography>
              Du hast noch kein Spiel in deiner Sammlung erfasst. Klicke{" "}
              <Link href="/add-game">hier</Link> um dein erstes Spiel zu
              erfassen.
            </Typography>
          </Box>
        )
      ) : (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            mb: 2,
            overflowX: "scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": { display: "none" },
            justifyContent: { xl: "center" },
          }}
        >
          {categoryList.map((category) => (
            <Chip
              color="primary"
              label={category.name}
              key={category.id}
              variant={searchCategory === category.id ? "filled" : "outlined"}
              onClick={() => handleCategoryFilterClick(category.id)}
              onDelete={
                searchCategory === category.id
                  ? () => handleCategoryFilterClick("")
                  : null
              }
            />
          ))}
        </Stack>
      )}

      {(searchString || searchCategory) &&
        (isLoading ? (
          <Box m={2}>
            <Stack
              direction="row"
              sx={{
                justifyContent: "center",
                alignItems: "end",
                "@keyframes bouncing-loader": {
                  to: {
                    opacity: "0.1",
                    transform: "translateY(-8px)",
                  },
                },
                "& div": {
                  width: 4,
                  height: 4,
                  m: "3px",
                  borderRadius: "50%",
                  backgroundColor: "primary.main",
                  opacity: 1,
                  animation: "bouncing-loader 0.6s infinite alternate",
                },
                "& div:nth-child(3)": {
                  animationDelay: "0.2s",
                },
                "& div:nth-child(4)": {
                  animationDelay: "0.4s",
                },
              }}
            >
              <Typography align="center">Suche läuft </Typography>
              <div />
              <div />
              <div />
            </Stack>
          </Box>
        ) : (
          <Box m={2}>
            <Typography align="center">
              Suchergebnis: {metaInfo.searchResults} / {metaInfo.totalGames}{" "}
              Spielen{" "}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(metaInfo.searchResults / metaInfo.totalGames) * 100}
            />
          </Box>
        ))}

      <Grid container spacing={3}>
        {gameList.map((game) => (
          <Grid item xs={12} sm={6} lg={4} xl={3} key={game.id}>
            <GameCard
              name={game.name}
              id={game.id}
              rating={game.rating}
              location={game.location}
              lastPlay={game.last_match}
              minAge={game.min_age}
              minPlayers={game.min_players}
              maxPlayers={game.max_players}
              playtime={game.playtime}
              profilePicture={game.image}
              currentLend={game.current_lend}
            />
          </Grid>
        ))}
      </Grid>

      {Math.ceil(metaInfo.searchResults / resultLimit) > 1 && (
        <FlexBox justifyContent="center" mt={5}>
          <Pagination
            page={page}
            count={Math.ceil(metaInfo.searchResults / resultLimit)}
            color="primary"
            variant="outlined"
            onChange={handleChangePage}
          />
        </FlexBox>
      )}
      {(metaInfo.privileges?.owner === 1 ||
        metaInfo.privileges?.game_insert === 1) && (
        <SpeedDial
          ariaLabel="Spielmenü"
          sx={{
            position: "fixed",
            "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
              bottom: 16,
              right: 16,
            },
          }}
          icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
          open={openSpeedDial}
          onOpen={handleChangeSpeedDial}
          onClose={handleChangeSpeedDial}
        >
          <SpeedDialAction
            key="add"
            icon={
              <Link href="/add-game">
                <AddIcon color="primary" sx={{ display: "block" }} />
              </Link>
            }
            tooltipTitle="Spiel hinzufügen"
          />
        </SpeedDial>
      )}
    </Box>
  );
};

export default Home;
