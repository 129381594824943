import React, { Component } from "react";
import AddIcon from '@mui/icons-material/Add';
import PermissionDataService from "../../services/permission.service";
import withStyles from '@mui/styles/withStyles';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Redirect } from "react-router-dom";
import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";


import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  IconButton,
  Box,
} from '@mui/material';


const useStyles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  chevron: {
    textAlign: 'right'
  },
  collectionIcon: {
    verticalAlign: 'bottom'
  },
  delete: {
    color: 'red',
  },
  contentBox: {
    width: '100%',
    maxWidth: 450,
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
});


class Permissions extends Component {

  constructor(props) {
    super(props);    

    this.alertDialog = this.alertDialog.bind(this);
    this.handleDeletePermission = this.handleDeletePermission.bind(this);
    this.handleChangeSpeedDial = this.handleChangeSpeedDial.bind(this)  

    this.props.setStateValue('isLoading', true)
    useHeaderStore.setState({ title: "Berechtigungen", help: "permissions"})

     this.state = {
       permissions: [],
       collection: this.props.match.params.collection_id,
       privileges: {},
       deletePermission: false,
       loading: true,

       openSpeedDial: false,
    }

  }
 
  componentDidMount() {
    this.retrievePermissions(this.state.collection);
  }
  
retrievePermissions(collection) {
  PermissionDataService.getAll(collection)
    .then(response => {
      this.setState({
        permissions: response.data.data,
        privileges: response.data.meta.privileges,
        loading: false
      })
      this.props.setStateValue('isLoading', false)
    })
    .catch((e) => {
      this.setState({
        loading: false
      })
      this.props.setStateValue("isLoading", false);
    })
}

handleDeletePermission(username) {
  this.setState({
    deletePermission: username,
  })
}

alertDialog(username) {
  const handleDialogClose = () => {
    this.setState({
      deletePermission: false,
    })
  };

  const handleDialogConfirm = (username) => {
    PermissionDataService.delete(username, this.state.collection)
    .then(response => {
      this.props.setStateValue('alertMessage', { severity: 'success', message: response.data.data.message, key: Math.random() })
      handleDialogClose();
      this.retrievePermissions(this.state.collection);
    })
    .catch(e => {
      this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Löschen des Benutzers', errorList: e.response.data.data, key: Math.random() })
    })
  } 
  return (

    <div>
     <Dialog
       open="open"
       onClose={handleDialogClose}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description"
     >
       <DialogTitle id="alert-dialog-title">{username + " wirklich löschen?"}</DialogTitle>

       <DialogActions>
         <Button onClick={handleDialogClose} color="primary">
           Abbrechen
         </Button>
         <Button onClick={() => handleDialogConfirm(username)} color="error" autoFocus>
           Löschen
         </Button>
       </DialogActions>
     </Dialog>
   </div>
    )
   }

handleChangeSpeedDial() {
  this.setState({
    openSpeedDial: !this.state.openSpeedDial
  })
} 


render() {
  const { loading, permissions, openSpeedDial, deletePermission, privileges } = this.state
  const { classes } = this.props;

  if (!loading && (privileges.owner === '1' || privileges.permission_modify === 1)) {
    return (
      <React.Fragment>
      { deletePermission? this.alertDialog(deletePermission) : null }
      <AccountDashboardLayout>
        <Box className={classes.contentBox}>
          <List component="nav">
          {permissions.map((permission) => (
            <ListItem>
              <ListItemText primary={permission.username} secondary={<>{permission.description && (<><InfoIcon className={classes.collectionIcon} /> {permission.description} </>)}{permission.owner === 1 && "Eigentümer"}</>} />
              { permission.owner !== 1 &&
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  component={Link}
                  to={`/settings/collections/${this.state.collection}/permissions/${permission.id}/edit`}
                  key="editUser"
                  size="large">
                  <EditIcon />
                </IconButton>
                { ( privileges.owner === 1 || privileges.permission_delete === 1 ) &&
                  <IconButton
                    edge="end"
                    color="error"
                    onClick={() => this.handleDeletePermission(permission.username)}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                }
              </ListItemSecondaryAction>
              }
            </ListItem>
          ))}
          </List>
        </Box>
      </AccountDashboardLayout>
      { (!loading && (privileges.owner === 1 || privileges.permission_insert === 1 )) &&
        <SpeedDial ariaLabel="Sammlungsmenü" className={classes.speedDial} icon={<SpeedDialIcon icon={<MoreVertIcon />} />} open={openSpeedDial} onOpen={this.handleChangeSpeedDial} onClose={this.handleChangeSpeedDial} >
          <SpeedDialAction key="add" icon={<AddIcon color="primary"/>} tooltipTitle="Benutzer hinzufügen" component={Link} to={`/settings/collections/${this.state.collection}/permissions/add`} />
        </SpeedDial>   
      }
      </React.Fragment>
    );
  } else if (!loading && privileges.permission_view === 1) {
    return (
      <React.Fragment>
      <AccountDashboardLayout>
        <Box className={classes.contentBox}>
          <List component="nav">
          {permissions.map((permission) => (
            <ListItem>
              <ListItemText primary={permission.username} secondary={<>{permission.description && (<><InfoIcon className={classes.collectionIcon} /> {permission.description} </>) }</>} />
            </ListItem>
          ))}
        </List>
        </Box>
      </AccountDashboardLayout>
      </React.Fragment>
    )
  }
  else {
    return (
      !loading && <Redirect to={{ pathname: `/` }} />
    )
  }
}
}

export default withStyles(useStyles)(Permissions)