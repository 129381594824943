
import {
  StyledIconButton,
  StyledTableCell,
  StyledTableRow,
} from "src/components/StyledComponents";
import moment from "moment";
// =================================================

const OrderRow = ({ history }) => {
  const { type, validFrom, validUntil, completedAt } = history;

  return (
    <StyledTableRow tabIndex={-1} role="checkbox">
      
      <StyledTableCell align="left">{type}</StyledTableCell>

      <StyledTableCell
        align="left"
        sx={{
          fontWeight: 400,
        }}
      >
         {moment(validFrom).format('DD.MM.YYYY')}
      </StyledTableCell>

      <StyledTableCell
        align="left"
        sx={{
          fontWeight: 400,
        }}
      >
        {moment(validUntil).format('DD.MM.YYYY')}
      </StyledTableCell>

      <StyledTableCell
        align="left"
        sx={{
          fontWeight: 400,
        }}>
          {moment(completedAt).format('DD.MM.YYYY')}
          </StyledTableCell>

    </StyledTableRow>
  );
};
export default OrderRow;
