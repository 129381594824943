import http from "../http-common";

class ClassLoanDataService {
  getAll(collection_id) {
    return http.get(`/loans?c=${collection_id}`);
  }
   
  get(id) {
    return http.get(`/loans/${id}`);
  }

  create(data) {
    return http.post(`/loans`, data);
  }

  update(id, data) {
    return http.put(`/loans/${id}`, data);
  }

  delete(id) {
    return http.delete(`/loans/${id}`);
  }

}

const LoanDataService = new ClassLoanDataService();
export default LoanDataService;
