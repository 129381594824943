import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom"; // Neuer Import
import { Formik, Form } from "formik";
import queryString from "query-string";
import { object, string } from "yup";

import UserDataService from "../../services/user.service";
import { useAlertMessageStore } from "src/store/store";

import {
  Box,
  Button,
  Container,
  Grid,
  FormControl,
  FormHelperText,
  Paper,
  TextField,
} from "@mui/material";

import Copyright from "src/components/copyright";
import { FeatureGrid } from "src/components/feature";

function ConfirmAccount(props) {
  const history = useHistory();
  const location = useLocation();
  const scrollToRef = useRef(null);
  const [token, setToken] = useState("");
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const params = queryString.parse(location.search);
    setToken(params.token);
    setUsername(params.username);
    if (params.token && params.username) {
      checkAccountToken(params.username, params.token);
    }
  }, [location.search]);

  const checkAccountToken = (username, token) => {
    setIsLoading(true);
    UserDataService.checkAccountToken(username, token)
      .then((response) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: "Der Account wurde erfolgreich aktiviert",
          },
        });
        history.push("/");
      })
      .catch((e) => {
        setIsLoading(false);
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Der Token ist ungültig",
          },
        });
      });
  };

  const _handleSubmit = ({ username, token }) => {
    checkAccountToken(username, token);
  };
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <FeatureGrid scrollToRef={scrollToRef} />
      <Grid
        item
        xs={12}
        md={5}
        component={Paper}
        elevation={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: {
            xs: "100vw",
          },
          minHeight: {
            xs: "100vh",
          },
          pb: {
            xs: 1,
          },
          order: {
            xs: 1,
            md: "initial",
          },
        }}
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Formik
            initialValues={{
              username: username,
              token: token,
            }}
            enableReinitialize
            validationSchema={object().shape({
              username: string().required("Bitte einen Usernamen eingeben"),
              token: string().required("Bitte einen Token eingeben"),
            })}
            onSubmit={({ username, token }) =>
              _handleSubmit({
                username,
                token,
              })
            }
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
              } = props;
              return (
                <Container component="main" maxWidth="xs">
                  <Form
                    onSubmit={handleSubmit}
                    style={{ width: "100%", mt: "8px" }}
                  >
                    <FormControl
                      fullWidth
                      margin="dense"
                      error={Boolean(touched.username && errors.username)}
                      variant="standard"
                    >
                      <TextField
                        id="username"
                        name="username"
                        label="Username"
                        type="text"
                        variant="outlined"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.username && errors.username)}
                      />
                      <FormHelperText
                        error={Boolean(touched.username && errors.username)}
                      >
                        {touched.username && errors.username
                          ? errors.username
                          : ""}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      fullWidth
                      margin="dense"
                      error={Boolean(touched.token && errors.token)}
                      variant="standard"
                    >
                      <TextField
                        id="token"
                        name="token"
                        label="Token"
                        type="text"
                        variant="outlined"
                        value={values.token}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.token && errors.token)}
                      />
                      <FormHelperText
                        error={Boolean(touched.token && errors.token)}
                      >
                        {touched.token && errors.token ? errors.token : ""}
                      </FormHelperText>
                    </FormControl>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={Boolean(!isValid || isLoading)}
                      sx={{
                        display: "block",
                        margin: "auto",
                        marginTop: "16px",
                      }}
                    >
                      {"Weiter"}
                    </Button>
                  </Form>
                  <Box mt={8}>
                    <Copyright />
                  </Box>
                </Container>
              );
            }}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ConfirmAccount;
