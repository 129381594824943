import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { format } from "date-fns";
import { useHeaderStore } from "src/store/store";
import MatchDataService from "src/services/match.service";

import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import Loading from "src/components/Loading";
import noImage from "src/images/no_image.png";

const PlayDetails = () => {
  let { playID } = useParams();
  const [loading, setLoading] = useState(true);
  const [playDetails, setPlayDetails] = useState({});

  useEffect(() => {
    useHeaderStore.setState({
      title: "Partiedetails",
      help: false,
    });

    const fetchPlayDetails = async () => {
      await MatchDataService.get(playID).then((response) => {
        setPlayDetails(response.data.match);
        setLoading(false);
      });
    };
    fetchPlayDetails();
  }, [playID]);

  if (loading) {
    return <Loading />;
  }
  return (
    <Box>
      <Paper sx={{ mb: 1 }}>
        <Typography variant="h5" component="h1" align="center" gutterBottom>
          {playDetails.game?.name}
        </Typography>
        <Container sx={{ textAlign: "center" }}>
          <Box
            component="img"
            sx={{
              height: "15vh",
            }}
            src={playDetails.game?.image || noImage}
            alt={playDetails.game?.name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = { noImage };
            }}
          />
        </Container>
        <Typography variant="subtitle1" align="center">
          Gespielt am:{" "}
          <b>{format(new Date(playDetails.date), "dd.MM.yyyy")}</b>
        </Typography>
      </Paper>
      <Paper sx={{ mb: 1 }}>
        <Typography
          variant="subtitle1"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Gespielte Erweiterungen ({playDetails.expansions.length})
        </Typography>
        {playDetails.expansions.length > 0 && (
          <TableContainer component={Paper}>
            <Table
              aria-label="expansionDetails"
              sx={{
                tableLayout: "fixed",
                "& tr:nth-child(even)": {
                  backgroundColor: "#f2f2f2",
                },
              }}
            >
              <TableBody>
                {playDetails.expansions.map((expansion) => {
                  return (
                    <TableRow>
                      <TableCell
                        component={Link}
                        to={`/games/${expansion.id}`}
                        key={expansion.id}
                      >
                        <noImage />
                        <Box
                          component="img"
                          sx={{
                            mr: 1,
                            maxHeight: "5vh",
                            float: "left",
                          }}
                          src={playDetails.game?.image || noImage}
                          alt={playDetails.game?.name}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = { noImage };
                          }}
                        />
                        <Typography>{expansion.name}</Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <Paper sx={{ mb: 1 }}>
        <Typography
          variant="subtitle1"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Spieler ({playDetails.players.length})
        </Typography>
        {playDetails.players.length > 0 && (
          <TableContainer component={Paper}>
            <Table
              aria-label="playerDetails"
              sx={{
                tableLayout: "fixed",
                "& tr:nth-child(even)": {
                  backgroundColor: "#f2f2f2",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Punkte
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Sieger
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {playDetails.players?.map((player) => {
                  return (
                    <TableRow>
                      <TableCell
                        component={Link}
                        to={`/statistic/collection/${playDetails.collection.id}/player/${player.player_id}`}
                        key={player.player_id}
                      >
                        {player.player_name}
                      </TableCell>
                      <TableCell align="right">{player.points}</TableCell>
                      <TableCell align="right">
                        {player.winner === 1 && (
                          <EmojiEventsIcon
                            sx={{
                              color: "gold",
                              verticalAlign: "middle",
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <Paper sx={{ mb: 1 }}>
        <Typography
          variant="subtitle1"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Anmerkung
        </Typography>
        <Typography>{playDetails.comment}</Typography>
      </Paper>
    </Box>
  );
};

export default PlayDetails;
