import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHeaderStore } from "src/store/store";
import BGDBDataService from "src/services/bgdb.service";

import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import AlarmIcon from "@mui/icons-material/Alarm";
import CasinoIcon from "@mui/icons-material/Casino";
import GroupIcon from "@mui/icons-material/Group";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

import Loading from "src/components/Loading";

const DiscoverGamePage = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState(null);
  useEffect(() => {
    useHeaderStore.setState({
      title: "Entdecken",
      help: false,
    });

    const fetchGames = async () => {
      await BGDBDataService.searchGame({
        query: `id=${id}`,
        params: "stats=1",
      }).then((response) => {
        setGame(response.data.games[0]);
        setLoading(false);
      });
    };

    fetchGames();
  }, []);

  if (loading) {
    return <Loading />;
  }
  console.dir(game);
  return (
    <Card>
      <Box
        component="img"
        src={game.image}
        alt={game.name}
        sx={{ width: "100%", maxHeight: "35vh", objectFit: "cover" }}
      />
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" gutterBottom>
              {game.name}
            </Typography>
          </Grid>
          <Grid item xs={3} alignContent="center">
            <Stack alignItems="center">
              <Typography gutterBottom>Spieler</Typography>
              <Stack direction="row">
                <GroupIcon fontSize="small" color="primary" />
                <Typography fontWeight="bold" gutterBottom>
                  {!game.minPlayers && !game.maxPlayers
                    ? "n/A"
                    : game.minPlayers && !game.maxPlayers
                    ? game.minPlayers
                    : !game.minPlayers && game.maxPlayers
                    ? game.maxPlayers
                    : game.minPlayers + " - " + game.maxPlayers}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3} alignContent="center">
            <Stack alignItems="center">
              <Typography gutterBottom>Spielzeit</Typography>
              <Stack direction="row">
                <AlarmIcon fontSize="small" color="primary" />
                <Typography fontWeight="bold" gutterBottom>
                  {game.playtime}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3} alignContent="center">
            <Stack alignItems="center">
              <Typography gutterBottom>Alter</Typography>
              <Stack direction="row">
                <SupervisorAccountIcon fontSize="small" color="primary" />
                <Typography fontWeight="bold" gutterBottom>
                  {game.minAge ? game.minAge + "+" : "n/A"}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3} alignContent="center">
            <Stack alignItems="center">
              <Typography gutterBottom>Bewertung</Typography>
              <Stack direction="row">
                <CasinoIcon fontSize="small" color="primary" />
                <Typography fontWeight="bold" gutterBottom>
                  {game.rating ? Number(game.rating).toFixed(1) + "/10" : "n/A"}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Divider sx={{ width: "100%", my: 1 }} />
          <Box m={2}>
            <Typography fontWeight="bold">Beschreibung</Typography>
            <Typography
              dangerouslySetInnerHTML={{ __html: game.description }}
            />
          </Box>
        </Grid>
      </Box>
    </Card>
  );
};

export default DiscoverGamePage;
