import { Box, Grid } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import CasinoIcon from "@mui/icons-material/Casino";
import GroupsIcon from "@mui/icons-material/Groups";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import FeaturePaper from "./FeaturePaper";
import loginBackground from "src/images/login_background.jpg";
const FeatureGrid = ({scrollToRef}) => {
return (
    <Grid
    item
    xs={false}
    md={7}
    ref={scrollToRef}
    sx={{
      backgroundImage: `url(${loginBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: {
        xs: "100vh",
      },
      pb: {
        xs: 1,
      },
      order: {
        xs: 2,
        md: "initial",
      },
    }}
  >
    <Box>
      <FeaturePaper
        icon={CategoryIcon}
        header="Bring Ordnung in deine Sammlung"
        body="Mit Spieleordner.de kannst du ganz einfach deine
                      gesamte Spielesammlung inventarisieren. Praktische
                      Features wie der Import von Informationen zu Spielen
                      machen die Inventarisierung und das spätere Finden von
                      Spielen kinderleicht."
      />
      <FeaturePaper
        icon={CasinoIcon}
        header="Erfasse deine Partien"
        body="Du liebst Statistiken? Wir auch! Mit Spieleordner.de
                      kannst du ganz einfach deine ganzen Partien erfassen
                      und so spannende Statistiken einsehen. Wer hat die
                      höchste Siegesrate? Was ist euer meist gespieltes
                      Spiel?"
      />
      <FeaturePaper
        icon={GroupsIcon}
        header="Spiele und Sammle gemeinsam"
        body="Spieleordner.de ermöglicht es dir, dass du gemeinsam
                      mit Freunden, Partner oder gar deinem Haustier an
                      deiner Spielesammlung arbeitest. So kannst du z.B.
                      Spiele verleihen oder die Sammlung gemeinsam pflegen.
                      Der Planung der nächsten gemeinsamen Runde steht somit
                      nichts mehr im Weg!"
      />
      <FeaturePaper
        icon={LoyaltyIcon}
        header="Hier findest du keine Kekse oder Werbung"
        body="Wir lieben Spieleordner.de, darum gibt es bei uns
                      keine Werbung, keine Cookies oder sonst etwas. Wir
                      entwickeln Spieleordner.de aus Leidenschaft und als
                      passionierte Alles-Spieler. Also probier es aus!"
      />
    </Box>
  </Grid>
)

}

export default FeatureGrid;