import React, { Component } from "react";
import CollectionDataService from "../../../../services/collection.service";
import withStyles from "@mui/styles/withStyles";
import EditIcon from "@mui/icons-material/Edit";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";
import moment from "moment";
import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  InputAdornment,
  Typography,
  IconButton,
  Divider,
  TextField,
  Box,
} from '@mui/material';

const useStyles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  chevron: {
    textAlign: "right",
  },
  delete: {
    color: "red",
  },
  editCollectionName: {
    marginBottom: "20px",
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  contentList: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  contentBox: {
    width: "100%",
    maxWidth: 450,
  },
  mobile: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    width: "100%",
    maxWidth: 360,
    height: "calc(100vh - 112px)",
    borderRight: "1px solid #ccc",
  },
});

class CollectionDetails extends Component {
  constructor(props) {
    super(props);
    this.editCollectionName = this.editCollectionName.bind(this);
    this.onChangeCollectionName = this.onChangeCollectionName.bind(this);
    this.saveCollectionName = this.saveCollectionName.bind(this);
    this.handleDeleteCollection = this.handleDeleteCollection.bind(this);

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({ title: "Sammlungsdetails", help: "collectionDetails"})

    this.state = {
      collection: [],
      collectionName: "",
      edit: false,
      editCollectionNameError: [],

      deleteCollection: false,

      privileges: {},
    };
  }

  componentDidMount() {
    const collection_id = this.props.match.params.collection_id;
    this.retrieveCollectionDetails(collection_id);
  }

  retrieveCollectionDetails(id) {
    CollectionDataService.get(id)
      .then((response) => {
        this.setState({
          collectionName: response.data.data[0].collection_name,
          collection: response.data.data[0],
          privileges: response.data.meta.privileges,
        });
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          this.props.history.push(`/settings/collections`);
        }
      });
  }

  editCollectionName() {
    this.setState({
      edit: true,
    });
  }

  onChangeCollectionName(e) {
    this.setState({
      collectionName: e.target.value,
    });
  }

  saveCollectionName() {
    var data = {
      collectionID: this.state.collection.collection_id,
      collectionName: this.state.collectionName,
    };

    if (data.collectionName !== "") {
      CollectionDataService.update(data.collectionID, data)
        .then((response) => {
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Der Sammlungstitel wurde erfolgreich geändert",
            key: Math.random(),
          });
          this.setState({
            edit: false,
          });
          this.retrieveCollectionDetails(data.collectionID);
        })
        .catch((e) => {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler beim Ändern des Sammlungstitels",
            key: Math.random(),
          });
          console.log(e);
        });
    } else {
      this.props.setStateValue("alertMessage", {
        severity: "error",
        message: "Fehler beim Ändern des Sammlungstitels",
        key: Math.random(),
      });
      this.setState({
        editCollectionNameError: {
          status: true,
          message: "Der Name darf nicht leer sein.",
        },
      });
    }
  }

  handleDeleteCollection() {
    this.setState({
      deleteCollection: true,
    });
  }

  alertDialog(title) {
    const handleDialogClose = () => {
      this.setState({
        deleteCollection: false,
      });
    };

    const handleDialogConfirm = () => {
      CollectionDataService.delete(this.state.collection.collection_id)
        .then((response) => {
          handleDialogClose();
          if (
            localStorage.getItem("collectionID") ==
            this.state.collection.collection_id
          ) {
            localStorage.setItem(
              "collectionID",
              response.data.meta.session.collectionID
            );
          }
          this.props.setStateValue("forceCollectionUpdate", true);
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Die Sammlung wurde erfolgreich gelöscht",
            key: Math.random(),
          });
          this.props.history.push(`/settings/collections`);
        })
        .catch((e) => {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler beim Löschen der Sammlung",
            key: Math.random(),
          });
          this.setState({
            deleteCollection: false,
          });
          console.log(e);
        });
    };
    if (this.state.privileges.owner === 1) {
      return (
        <div>
          <Dialog
            open="open"
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {title + " wirklich löschen?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Es werden alle Statistiken und Historien in Verbindungen mit
                dieser Sammlung gelöscht.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Abbrechen
              </Button>
              <Button
                onClick={() => handleDialogConfirm()}
                color="error"
                autoFocus
              >
                Löschen
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    } else {
      return (
        <div>
          <Dialog
            open="open"
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {title + " wirklich verlassen?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Es werden alle Zugehörigkeiten zu dieser Sammlung gelöscht.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Abbrechen
              </Button>
              <Button
                onClick={() => handleDialogConfirm()}
                color="error"
                autoFocus
              >
                Verlassen
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }

  render() {
    const { collection, collectionName, privileges, edit, deleteCollection } =
      this.state;
    const { classes, alwaysOpen } = this.props;

    return (
      <React.Fragment>
        {deleteCollection ? this.alertDialog(collection.collection_name) : null}
        <AccountDashboardLayout>
          <Box className={alwaysOpen ? classes.contentBox : classes.mobile}>
            {edit ? (
              <TextField
                value={collectionName}
                onChange={this.onChangeCollectionName}
                className={classes.editCollectionName}
                error={this.state.editCollectionNameError.status}
                helperText={this.state.editCollectionNameError.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="save"
                        onClick={this.saveCollectionName}
                        size="large"
                      >
                        <CheckIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="standard"
              />
            ) : (
              <Typography variant="h5" align="center" gutterBottom>
                {collection.collection_name}
                {privileges.owner === 1 && (
                  <IconButton
                    title="Sammlungsnamen bearbeiten"
                    size="large"
                    onClick={this.editCollectionName}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Typography>
            )}
            <List component="nav" className={classes.contentList}>
              <ListItem>
                <ListItemText
                  primary={collection.collection_owner}
                  secondary="Eigentümer"
                />
              </ListItem>
              {privileges && (
                <>
                  <ListItem>
                    <ListItemText
                      primary={moment(collection.created_at).format(
                        "DD.MM.YYYY"
                      )}
                      secondary="Erstellt am"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={collection.count_games}
                      secondary="Anzahl Spiele"
                    />
                  </ListItem>
                </>
              )}
              {((privileges.owner === 1 ||
                privileges.collectiondetail_modify === 1) && (
                <>
                  <ListItemButton
                    component={Link}
                    to={`/settings/collections/${collection.collection_id}/locations`}
                    key="locations"
                  >
                    <ListItemText primary="Standorte verwalten" />
                    <ListItemIcon sx={{ justifyContent: "end" }}>
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </ListItemButton>
                  <ListItemButton
                    component={Link}
                    to={`/settings/collections/${collection.collection_id}/players`}
                    key="players"
                  >
                    <ListItemText primary="Mitspieler verwalten" />
                    <ListItemIcon sx={{ justifyContent: "end" }}>
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </ListItemButton>
                  <ListItemButton
                    component={Link}
                    to={`/settings/collections/${collection.collection_id}/player-groups`}
                    key="player-groups"
                  >
                    <ListItemText primary="Spielergruppen verwalten" />
                    <ListItemIcon sx={{ justifyContent: "end" }}>
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </ListItemButton>
                </>
              )) ||
                (privileges && (
                  <>
                    <ListItemButton
                      component={Link}
                      to={`/settings/collections/${collection.collection_id}/locations`}
                      key="locations"
                    >
                      <ListItemText primary="Standorte ansehen" />
                      <ListItemIcon sx={{ justifyContent: "end" }}>
                        <ChevronRightIcon />
                      </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={`/settings/collections/${collection.collection_id}/players`}
                      key="players"
                    >
                      <ListItemText primary="Mitspieler ansehen" />
                      <ListItemIcon sx={{ justifyContent: "end" }}>
                        <ChevronRightIcon />
                      </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to={`/settings/collections/${collection.collection_id}/player-groups`}
                      key="player-groups"
                    >
                      <ListItemText primary="Spielergruppen ansehen" />
                      <ListItemIcon sx={{ justifyContent: "end" }}>
                        <ChevronRightIcon />
                      </ListItemIcon>
                    </ListItemButton>
                  </>
                ))}
              {(privileges.owner === 1 || privileges.permission_modify === 1 || privileges.permission_view === 1) && (<>
                <ListItemButton
                component={Link}
                to={`/settings/collections/${collection.collection_id}/permissions`}
                key="permissions"
                >
                <ListItemText primary={(privileges.owner === 1 || privileges.permission_modify === 1) ? "Berechtigungen verwalten" : "Berechtigungen ansehen"} />
                <ListItemIcon sx={{ justifyContent: "end" }}>
                  <ChevronRightIcon />
                </ListItemIcon>
              </ListItemButton>
              </>)}
              <Divider />
              {privileges.owner === 1 ? (
                <ListItemButton>
                  <ListItemText
                    primary="Sammlung löschen"
                    className={classes.delete}
                    onClick={this.handleDeleteCollection}
                  />
                </ListItemButton>
              ) : (
                <ListItemButton>
                  <ListItemText
                    primary="Sammlung verlassen"
                    className={classes.delete}
                    onClick={this.handleDeleteCollection}
                  />
                </ListItemButton>
              )}
            </List>
          </Box>
        </AccountDashboardLayout>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(CollectionDetails);
