import { Avatar, Box, Card, Grid, Skeleton, Stack } from "@mui/material";
import { East } from "@mui/icons-material";

const GameCardSkeleton = () => {
  return (
    <Grid container spacing={3}>
      {Array.from(new Array(1)).map((item, index) => (
        <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
          <Card>
            <Skeleton variant="rectangular" width="100%">
              <div style={{ paddingTop: "57%" }} />
            </Skeleton>
            <Stack direction="row">
              <Grid container justifyContent="flex-start">
                <Skeleton
                  variant="circular"
                  sx={{
                    width: 64,
                    height: 64,
                    mt: "-32px",
                  }}
                >
                </Skeleton>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Skeleton
                  sx={{
                    mx: 0.5,
                  }}
                >
                  <East />
                </Skeleton>
              </Grid>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default GameCardSkeleton;
