import { Box, Typography } from "@mui/material"
const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box pt={1}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };
  export default TabPanel;
  