import { authenticationService } from "../auth/authentication.service";
var io = require("socket.io-client");

export const socketService = {
  connect,
  disconnect,
};

function connect(token) {
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.token) {
    const socket = io.connect("/", {
      query: { token: currentUser.token },
    });
    socket.on("error", function (err) {
      throw new Error(err);
    });
    return socket;
  }
}

function disconnect() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  localStorage.removeItem("collectionID");
  localStorage.removeItem("session");
}
