import React, { useState, useEffect } from "react";
import { useAlertMessageStore, useHeaderStore } from "src/store/store";
import LoanDataService from "src/services/loan.service";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { deDE } from "@mui/x-data-grid/locales";
import { Box } from "@mui/material";
import Loading from "src/components/Loading";

const columns = [
  { field: "date_start", headerName: "Von", type: "date", width: 150 },
  { field: "date_end", headerName: "Bis", type: "date", width: 150 },
  { field: "name", headerName: "Name", width: 250 },
  { field: "player_name", headerName: "Verliehen an", width: 300 },
  { field: "comment", headerName: "Anmerkung", width: 300 },
];

const sortModel = [
  {
    field: "date_start",
    sort: "desc",
  },
];
const LoanHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loanList, setLoanList] = useState([]);
  const collection_id = localStorage.getItem("collectionID");

  // Setting title and help using store
  useHeaderStore.setState({
    title: "Leihverlauf",
    help: "loanHistory",
  });

  useEffect(() => {
    const fetchLoanList = async () => {
      setIsLoading(true);
      try {
        const response = await 
        LoanDataService.getAll(collection_id);
        const data = response.data.data.map((val) => ({
          ...val,
          date_start: val.date_start ? new Date(val.date_start) : null,
          date_end: val.date_end ? new Date(val.date_end) : null,
        }));
        setLoanList(data);
      } catch (error) {
        console.dir(error)
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Lades der Leihen",
          },
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchLoanList();
  }, [collection_id]);

  return (
    <Box sx={{ height: "80vh" }}>
      {isLoading && <Loading />}
      <DataGrid
        initialState={{ sortModel: sortModel }}
        rows={loanList}
        columns={columns}
        checkboxSelection
        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        slots={{
          toolbar: GridToolbar,
        }}
        loading={isLoading}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: {
              delimiter: ";",
            },
          },
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
        }}
      />
    </Box>
  );
};

export default LoanHistory;
