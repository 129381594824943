import * as React from "react";
import { Box, Typography } from '@mui/material';

export default function MemberShipPriceBox(props) {
  const { price, offer } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {offer ? (
        <>
          <Typography variant="h4">{offer.price % 1 ? offer.price.replace('.', ',') : Math.trunc(offer.price)}€</Typography>
          <Typography
            variant="h5"
            sx={{
              position: "relative",
              "&::before": {
                top: "50%",
                background: "red",
                opacity: ".7",
                content: '" "',
                width: "110%",
                position: "absolute",
                height: ".1em",
                borderRadius: ".1em",
                left: "-5%",
                whiteSpace: "nowrap",
                display: "block",
                transform: "rotate(-15deg)",
              },
            }}
          >
            {price % 1 ? price.replace('.', ',') : Math.trunc(price)}€
          </Typography>
        </>
      ) : (
        <Typography variant="h4">{price % 1 ? price.replace('.', ',') : Math.trunc(price)}€</Typography>
      )}
      <Typography variant="subtitle2">pro Jahr</Typography>
    </Box>
  );
}
