import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { deDE } from "@mui/x-data-grid/locales";
import { useAlertMessageStore, useHeaderStore } from "src/store/store";
import { Box } from "@mui/material";
import GameDataService from "../../services/game.service";
import Loading from "src/components/Loading";

const columns = [
  { field: "name", headerName: "Name", width: 200 },
  { field: "ean", headerName: "EAN", width: 150 },
  { field: "maingame_title", headerName: "Hauptspiel", width: 200 },
  { field: "designer", headerName: "Autor", width: 150 },
  { field: "publisher", headerName: "Publisher", width: 150 },
  {
    field: "min_players",
    headerName: "Min. Spieler",
    type: "number",
    width: 100,
  },
  {
    field: "max_players",
    headerName: "Max. Spieler",
    type: "number",
    width: 100,
  },
  { field: "min_age", headerName: "Alter", type: "number", width: 100 },
  { field: "playtime", headerName: "Dauer", type: "number", width: 100 },
  {
    field: "date_purchased",
    headerName: "Anschaffung",
    type: "date",
    width: 150,
  },
  {
    field: "yearpublished",
    headerName: "Veröffentlichung",
    type: "string",
    width: 100,
  },
  { field: "location_name", headerName: "Standort", width: 150 },
  {
    field: "coop_text",
    headerName: "Koop",
    width: 100,
    valueGetter: (value, row) => {
      if (row.coop) {
        return "Ja";
      } else {
        return "Nein";
      }
    },
  },
  { field: "description", headerName: "Hinweis", width: 200 },
  {
    field: "last_match",
    headerName: "Zuletzt gespielt",
    type: "date",
    width: 150,
  },
  { field: "bgdb_id", headerName: "BGG-ID", width: 100 },
];

const sortModel = [
  {
    field: "name",
    sort: "asc",
  },
];

const AdvancedSearch = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [gameList, setGameList] = useState([]);
  const collection_id = localStorage.getItem("collectionID");

  useHeaderStore.setState({
    title: "Erweiterte Suche",
    help: "advancedSearch",
  });

  useEffect(() => {
    const fetchGameList = async () => {
      setIsLoading(true);
      try {
        const response = await GameDataService.findByName(
          "",
          "",
          collection_id
        );
        const data = response.data.data.map((val) => ({
          ...val,
          last_match: val.last_match ? new Date(val.last_match) : null,
          date_purchased: val.date_purchased
            ? new Date(val.date_purchased)
            : null,
        }));
        setGameList(data);
      } catch (error) {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Lades der Spiele",
          },
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchGameList();
  }, [collection_id]);

  return (
    <Box sx={{ height: "80vh" }}>
      {isLoading && <Loading />}
      <DataGrid
        initialState={{ sortModel: sortModel }}
        rows={gameList}
        columns={columns}
        checkboxSelection
        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        slots={{
          toolbar: GridToolbar,
        }}
        loading={isLoading}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: {
              delimiter: ";",
            },
          },
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
        }}
      />
    </Box>
  );
};

export default AdvancedSearch;
