import { Link, Typography } from "@mui/material";

const Copyright = () => {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      sx={{ mt: 5 }}
    >
      <Link color="inherit" href="/privacy">
        Datenschutz
      </Link>
      {" | "}
      <Link color="inherit" href="/legalNotice">
        Impressum
      </Link>
      {" | © "}
      <Link color="inherit" href="/">
        Spieleordner
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
};

export default Copyright;
