import axios from "axios";
import { authHeader } from "./helpers/auth-header";
import { authenticationService } from "./auth/authentication.service";

const base_url = process.env.REACT_APP_BASE_URL;

const http = axios.create({
  baseURL: `${base_url}/api`,
  headers: { "Content-type": "application/json" },
});

http.interceptors.request.use(function (config) {
  let authKey = authHeader();
  config.headers["Authorization"] = authKey;
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { status } = error.response;
      const { url } = error.response.config;
      const unauthorizedRoutes = ["/users/resetpassword"];
      if (!unauthorizedRoutes.includes(url) === -1 && status === 401) {
        authenticationService.logout();
        return;
      }
    }

    return Promise.reject(error);
  }
);

export default http;
