import http from "../http-common";

class ClassContactDataService {
  createSingle(data){
    return http.post(`/contact/`, data);
  }
}

const ContactDataService = new ClassContactDataService();
export default ContactDataService;
