import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import EventDataService from "../../services/event.service";
import moment from 'moment';

import {
  Backdrop,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

const useStyles = theme => ({
  root: {
    '& .MuiButton-root': {
      [theme.breakpoints.down('md')]: {
        display: 'inline-block',
      }
    },
    '& .Mui-even': {
      backgroundColor: '#f2f2f2'
    },
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: '#f6f6f6',
      },
    },
  },
  statisticHeader: {
    marginTop: '1em',
  },
  tableAltTable: {
    '& tr:nth-child(even)': {
      backgroundColor: '#f2f2f2'
    },
  },
});


class EventDetails extends Component {

  constructor(props) {
    super(props);    

    this.event_id = this.props.match.params.id;

    this.props.setStateValue('isLoading', true)
    this.props.setStateValue('pageTitle', 'Eventdetails')
    this.props.setStateValue('help', false);

    this.state = {
      event: [],
    }

  }

  componentDidMount() {
    this.retrieveEvent(this.event_id);
  }
  

  retrieveEvent(id) {
    EventDataService.getByID(id)
    .then(response => {
      this.setState({
        event: response.data.data,
      })
      this.props.setStateValue('isLoading', false)
    })

}
 
  render() {
    const { isLoading, event }  = this.state
    const { classes } = this.props;

    return ( 
      <React.Fragment>
      { isLoading && 
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      <Typography variant="h5" align="center" gutterBottom>Allgemein</Typography>
      <TableContainer component={Paper}>
        <Table className={classes.tableAltTable} aria-label="statistic-details">
          <TableBody>
            <TableRow>
              <TableCell>Titel</TableCell>
              <TableCell align="right">{event.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ort</TableCell>
              <TableCell align="right">{event.location}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Beschreibung</TableCell>
              <TableCell align="right">{event.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell align="right">{event.date}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      </React.Fragment>

    )
  }
}

export default withStyles(useStyles)(EventDetails)