import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import OrderDataService from "../services/order.service";
import moment from "moment";

import {
  useTheme,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

export default function UpgradeDialog(props) {
  const { coupon, handleDialogClose, setStateValue, history } = props;

  const handleRedeemCoupon = () => {
    OrderDataService.redeemCoupon({coupon: coupon.code})
    .then((response) => {
      setStateValue("alertMessage", {
        severity: "success",
        message: "Der Coupon wurde erfolgreich eingelöst.",
        key: Math.random(),
      });
      history.push(`/settings/account/membership`)
    })
    .catch((e) => {
      setStateValue("alertMessage", {
        severity: "error",
        message: "Fehler beim Einlösen des Coupons.",
        key: Math.random(),
      });
      handleDialogClose();
    });
  }

  return (
    <div>
      {coupon.value === "100.00" && coupon.value_sign === "%" ? (
        <Dialog
          open={true}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Coupon einlösen</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Mit diesem Coupon bekommst du die ${coupon.type}-Mitgliedschaft für ${coupon.duration} Monate kostenlos.`}
            </DialogContentText>
            <DialogContentText id="alert-dialog-information">
              <Typography>
                Mit einem Klick auf "Einlösen" beginnt automatisch die Laufzeit deiner Mitgliedschaft.
              </Typography>
              <Typography>
                Sofern du bereits eine laufende Mitgliedschaft hast, wird diese entsprechend verlängert.
              </Typography>
            </DialogContentText>
            <Container maxWidth="xs" sx={{ padding: "30px" }}></Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="error">
              Abbrechen
            </Button>
            <Button onClick={handleRedeemCoupon} color="primary" variant="contained" autoFocus>
              Einlösen
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={true}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Coupon einlösen</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Mit diesem Coupon erhälst du ${coupon.value}${coupon.value_sign} Rabatt auf die ${coupon.type}-Mitgliedschaft.`}
            </DialogContentText>
            <DialogContentText id="alert-dialog-information">
              Mit einem Klick auf "Einlösen" wird dir der Rabatt auf der
              Übersichtsseite angezeigt und an der Kasse abgezogen.
            </DialogContentText>
            <Container maxWidth="xs" sx={{ padding: "30px" }}></Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="error">
              Abbrechen
            </Button>
            <Button color="primary" variant="contained" autoFocus>
              Einlösen
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
