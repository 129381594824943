import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { NotificationSubscription } from '../../../components/notificationSubscription';
import NotificationDataService from "../../../services/notification.service";
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";


import {
  IconButton,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Box,
} from '@mui/material';


const useStyles = makeStyles((theme) => ({
  contentList: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
  contentBox: {
    width: '100%',
    maxWidth: 450,
  }
}));


export default function NotificationSettings(props) {
  const classes = useStyles();

  const [settings, setSettings] = useState({})
  const updateHeader = useHeaderStore(state => state.updateHeader);

  useEffect(() => {
    props.setStateValue('isLoading', true)
    updateHeader({title: "Benachrichtigungen", help: "notifications"});
    checkSubscriptionSettings()
   }, [])

  function checkSubscriptionSettings() {
    NotificationDataService.getSubscriptionSettings()
    .then(function(response) { 
      let groupedByCategory = groupBy(response.data.data, 'category')
      setSettings(groupedByCategory)
      props.setStateValue('isLoading', false)
    })
    .catch(function(err) {
      console.log(err)
    })
  }


  function handleClick(setting, setting_index, action) {
    var tmpSettings = {...settings}
    tmpSettings[setting.category][setting_index][`${action}_subscription`] = !setting[`${action}_subscription`]
    switch(action) {
      case 'notification':
        if(setting[`${action}_subscription`]) {
          NotificationDataService.subscribeNotification(setting.id)
          .then(function(response) {
            setSettings(tmpSettings)
          })
          .catch(function(err) {
            props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Aktualisieren der Einstellungen', key: Math.random() })
          })
        } else {
            NotificationDataService.unsubscribeNotification(setting.id)
            .then(function(response) {
              setSettings(tmpSettings)
            })
            .catch(function(err) {
              props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Aktualisieren der Einstellungen', key: Math.random() })
            })
        }
      break;
      case 'mail':
        if(setting[`${action}_subscription`]) {
          NotificationDataService.subscribeMail({action_id : setting.id})
          .then(function(response) {
            setSettings(tmpSettings)
          })
          .catch(function(err) {
            props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Aktualisieren der Einstellungen', key: Math.random() })
          })
        } else {
            NotificationDataService.unsubscribeMail({action_id : setting.id})
            .then(function(response) {
              setSettings(tmpSettings)
            })
            .catch(function(err) {
              props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Aktualisieren der Einstellungen', key: Math.random() })
            })
        }
        break;
    }
  }

  function groupBy (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
  };


  return (
    <React.Fragment>
    <AccountDashboardLayout>
    <Box className={classes.contentBox}>
      <List component="nav" className={classes.contentList}>
        {Object.keys(settings).map((category, index) => (
          <>
          <ListSubheader>{category == 1 ? 'Spiele' : category == 2 ? 'Partien' : 'Leihen'}</ListSubheader>
          {settings[category].map((setting, setting_index) => (
            <ListItem >
            <ListItemText primary={setting.description} />
            <ListItemSecondaryAction>
              <IconButton
                aria-label="notification_subscription"
                color="inherit"
                className={classes.goBack}
                onClick={() => handleClick(setting, setting_index, 'notification')}
                size="small">
                <NotificationsIcon color={setting.notification_subscription ? 'success' : ''} /> 
              </IconButton>
              <NotificationSubscription  endpoint={setting.push_endpoint} action={setting.id} initState={setting.push_subscription} />
              <IconButton
                aria-label="mail_subscription"
                color="inherit"
                className={classes.goBack}
                onClick={() => handleClick(setting, setting_index, 'mail')}
                size="small">
                <EmailIcon color={setting.mail_subscription ? 'success' : ''} />
              </IconButton>
            </ListItemSecondaryAction>  
            </ListItem>
          ))}
          <Divider /></>
        ))}
      </List>
    </Box>
    </AccountDashboardLayout>
    </React.Fragment>
  );
}