import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import { alpha, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  useGameSearchStore,
  useCollectionStore,
  useHeaderStore,
} from "../../store/store";

import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  InputBase,
  InputAdornment,
  Container,
} from "@mui/material";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    maxWidth: "1432px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "240px",
      maxWidth: "calc(99.9% - 240px)",
    },
    [theme.breakpoints.up("xxl")]: {
      paddingLeft: "24px",
    },
    [theme.breakpoints.up("xxxl")]: {
      marginLeft: "auto",
      maxWidth: "1872px",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  pageTitle: {
    position: "relative",
    marginRight: "auto",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  notificationSettingsIcon: {
    position: "absolute",
    top: "0",
    bottom: "0",
    right: "0",
    margin: "auto",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "calc(100% + 2ch)",
      },
    },
    [theme.breakpoints.up("md")]: {
      width: "60vw",
      "&:focus": {
        width: "calc(60vw + 2ch)",
      },
    },
    [theme.breakpoints.up("md")]: {
      width: "30vw",
      "&:focus": {
        width: "calc(30vw + 2ch)",
      },
    },
    collectionSelection: {
      fontSize: "14px",
    },
  },
  popupPaper: {
    width: "100%",
    maxWidth: "50ch",
    height: "100%",
    maxHeight: "500px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "35ch",
    },
  },
  notificationUnread: {
    backgroundColor: "#547890",
    color: "#fff",
    "&:hover": {
      color: "#000",
    },
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

function PublicAppHeader(props) {
  const { window } = props;
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const gameSearch = useGameSearchStore((state) => state);
  const updateSearchString = useGameSearchStore(
    (state) => state.updateSearchString
  );
  const resetSearchString = useGameSearchStore(
    (state) => state.resetSearchString
  );

  const headerStore = useHeaderStore((state) => state);

  const collection = useCollectionStore((state) => state);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    let title = headerStore.title ? `Spieleordner - ${headerStore.title}` : "Spieleordner";
    document.title = notificationCount
      ? `(${notificationCount}) ${title}`
      : title || "Spieleordner";
  }, [headerStore]);

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img
          src="/logo192.png"
          alt="logo"
          style={{
            display: "block",
            margin: "auto",
            width: "192px",
            height: "192px",
          }}
        />
      </div>
      <Divider />
      <List>
        {[
          {
            titel: "Startseite",
            secondary: "",
            route: `/`,
          },
        ].map((menuObject, index) => (
          <ListItem
            button
            onClick={mobileOpen ? handleDrawerToggle : undefined}
            component={Link}
            to={menuObject.route}
            key={menuObject.titel}
          >
            <ListItemText
              primary={menuObject.titel}
              secondary={menuObject.secondary}
              key={menuObject.titel}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Container className={classes.container}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={classes.menuButton}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            {(location.pathname === "/" ||
              location.pathname === "/archive") && (
              <>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Suchen..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    value={gameSearch.searchString}
                    onChange={updateSearchString}
                    endAdornment={
                      <InputAdornment position="end" sx={{ color: "inherit" }}>
                        <IconButton
                          aria-label="reset search"
                          color="inherit"
                          onClick={resetSearchString}
                          size="large"
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
              </>
            )}
            {location.pathname !== "/" && location.pathname !== "/archive" && (
              <>
                <IconButton
                  aria-label="back"
                  color="inherit"
                  className={classes.goBack}
                  onClick={history.goBack}
                  size="small"
                >
                  <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h6" className={classes.pageTitle}>
                  {" "}
                  {headerStore.title}{" "}
                </Typography>
              </>
            )}
          </Container>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default PublicAppHeader;
