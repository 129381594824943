import * as React from "react";

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { getHelpText } from "src/helpers/helpText/helper";

export default function HelpBox(props) {
  const { func, handleHelpBoxClose } = props;

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleHelpBoxClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {getHelpText(func).title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <DialogContentText id="alert-dialog-information">
            {getHelpText(func).body}
          </DialogContentText>
          <Container maxWidth="xs" sx={{ padding: "30px" }}></Container>
        </DialogContent>
        <DialogActions>
          {/*<Button>FAQ</Button>*/}
          <Button
            color="primary"
            variant="contained"
            onClick={handleHelpBoxClose}
            autoFocus
          >
            Verstanden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
