import { Card, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Navigations from "./Navigations";
import { useLocation } from "react-router-dom";
import AccountDashboardHeader from "src/components/header/AccountDashboardHeader";
import Loading from "src/components/Loading";

const MainContainer = styled(Card)(({ theme }) => ({
  paddingBottom: "1.5rem",
  width: "fit-content",
  minWidth: "50%",
  [theme.breakpoints.down("md")]: {
    overflowY: "auto",
    width: "auto",
  },
  [theme.breakpoints.between("sm", "lg")]: {
    width: "fit-content",
    minWidth: "60%",
  },
  [theme.breakpoints.up("lg")]: {
    minWidth: "45%",
  },
  [theme.breakpoints.up("xl")]: {
    minWidth: "35%",
  },
}));

const AccountDashboardLayout = ({ isLoading, children }) => {
  const { pathname } = useLocation();
  const checkRouteMatch = () => {
    return pathname === "/settings";
  };

  // active route
  const currentRoute = checkRouteMatch();

  if (isLoading) {
    return (
      <Grid container spacing={3}>
        <Loading />
        <Grid
          item
          xxl={3}
          lg={4}
          xs={12}
          sx={{
            display: {
              xs: currentRoute ? "block" : "none",
              sm: currentRoute ? "block" : "none",
              md: currentRoute ? "block" : "none",
              lg: "block",
            },
          }}
        >
          <Navigations />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xxl={3}
        lg={4}
        xs={12}
        sx={{
          display: {
            xs: currentRoute ? "block" : "none",
            sm: currentRoute ? "block" : "none",
            md: currentRoute ? "block" : "none",
            lg: "block",
          },
        }}
      >
        <Navigations />
      </Grid>

      <Grid item xxl={9} lg={8} xs={12}>
        <AccountDashboardHeader navigation={<Navigations />} />
        {children && <MainContainer>{children}</MainContainer>}
      </Grid>
    </Grid>
  );
};
export default AccountDashboardLayout;
