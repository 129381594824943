import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
  StatiticHeader,
  StatisticTable,
} from "src/components/StyledComponents";
import TabPanel from "src/components/TabPanel";

const Players = ({ value, index, statistic, t, collection_id, ...other }) => {
  return (
    <TabPanel value={value} index={index} dir="ltr">
      <StatiticHeader variant="h5" align="center" gutterBottom>
        Statistik je Spieler
      </StatiticHeader>
      <TableContainer component={Paper} sx={{ marginBottom: "5px" }}>
        <StatisticTable aria-label="statistic-players">
          <TableBody>
            {statistic?.playerlist &&
              statistic.playerlist.map((player, index) => (
                <TableRow
                  component={Link}
                  to={`/statistic/collection/${collection_id}/player/${player.id}`}
                  key={index}
                >
                  <TableCell>{player.name}</TableCell>
                  <TableCell align="right">
                    <ChevronRight />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StatisticTable>
      </TableContainer>
    </TabPanel>
  );
};
export default Players;
