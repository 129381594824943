import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import { object, ref, string } from "yup";
import { authenticationService } from "../../auth/authentication.service";
import UserDataService from "../../services/user.service";
import { useAlertMessageStore } from "src/store/store";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  TextField,
} from "@mui/material";

import Copyright from "src/components/copyright";
import { FeatureGrid } from "src/components/feature";

// SignUp Component
const SignUp = () => {
  const history = useHistory();
  const scrollToRef = useRef(null);
  useEffect(() => {
    if (authenticationService.currentUserValue) {
      history.push("/");
    }
  }, [history]);

  // Handle Submit
  const handleSubmit = async (
    values,
    { setSubmitting, setFieldError, resetForm }
  ) => {
    const { username, firstname, lastname, email, password, password999 } =
      values;

    const data = {
      username,
      firstname,
      lastname,
      email,
      password,
      password999,
    };

    try {
      if (!password999) {
        await UserDataService.signup(data).then((response) => {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "success",
              message:
                "Um die Registrierung abzuschließen, haben wir dir eine E-Mail geschickt. Bitte klicke auf den Link um deinen Account zu aktivieren",
            },
          });
          history.push("/");
        });
      } else {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message:
              "Um die Registrierung abzuschließen, haben wir dir eine E-Mail geschickt. Bitte klicke auf den Link um deinen Account zu aktivieren",
          },
        });
        history.push("/");
      }
    } catch (error) {
      setSubmitting(false);
      if (error.status === 409) {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler bei der Registrierung",
            errorList: error.response.data
          },
        });
      } else {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler bei der Registrierung",
          },
        });
      }
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <FeatureGrid scrollToRef={scrollToRef} />
      <Grid
        item
        xs={12}
        md={5}
        component={Paper}
        elevation={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: {
            xs: "100vw",
          },
          minHeight: {
            xs: "100vh",
          },
          pb: {
            xs: 1,
          },
          order: {
            xs: 1,
            md: "initial",
          },
        }}
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Formik
            initialValues={{
              username: "",
              firstname: "",
              lastname: "",
              email: "",
              password: "",
              confirmPassword: "",
              password999: "",
            }}
            validationSchema={object().shape({
              username: string()
                .min(3, "Der Benutzername muss mindestens 3 Zeichen enthalten")
                .max(32, "Der Benutzername darf maximal 32 Zeichen enthalten")
                .required("Bitte einen Benutzernamen eingeben")
                .matches(
                  /^[a-zA-Z0-9]+$/,
                  "Der Benutzername darf nur aus Buchstaben und Zahlen bestehen"
                ),
              firstname: string()
                .max(32, "Der Vorname darf maximal 32 Zeichen enthalten")
                .required("Bitte einen Vornamen eingeben"),
              lastname: string()
                .max(32, "Der Nachname darf maximal 32 Zeichen enthalten")
                .matches(
                  /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\-]+$/,
                  "Bitte einen gültigen Nachnamen eingeben"
                )
                .required("Bitte einen Nachnamen eingeben"),
              email: string()
                .required("Bitte eine E-Mail eingeben")
                .email("Bitte eine gültige E-Mail eingeben"),
              password: string()
                .min(6, "Das Passwort muss mindestens 6 Zeichen enthalten")
                .max(64, "Das Passwort darf maximal 64 Zeichen enthalten")
                .required("Bitte ein Passwort eingeben"),
              confirmPassword: string()
                .oneOf([ref("password")], "Passwörter stimmen nicht überein")
                .required("Bitte das Passwort wiederholen"),
            })}
            onSubmit={handleSubmit}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              isSubmitting,
            }) => (
              <Container component="main" maxWidth="xs">
                {isSubmitting && (
                  <Backdrop sx={{ color: "#fff" }} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
                <Form
                  style={{ width: "100%", mt: "8px" }}
                  onSubmit={handleSubmit}
                >
                  <FormControl fullWidth margin="normal" variant="standard">
                    <TextField
                      id="username"
                      label="Benutzername"
                      name="username"
                      variant="outlined"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.username && errors.username)}
                    />
                    <FormHelperText
                      error={Boolean(touched.username && errors.username)}
                    >
                      {touched.username && errors.username
                        ? errors.username
                        : ""}
                    </FormHelperText>
                  </FormControl>
                  <FormControl fullWidth margin="normal" variant="standard">
                    <TextField
                      id="firstname"
                      label="Vorname"
                      name="firstname"
                      variant="outlined"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.firstname && errors.firstname)}
                    />
                    <FormHelperText
                      error={Boolean(touched.firstname && errors.firstname)}
                    >
                      {touched.firstname && errors.firstname
                        ? errors.firstname
                        : ""}
                    </FormHelperText>
                  </FormControl>
                  <FormControl fullWidth margin="normal" variant="standard">
                    <TextField
                      id="lastname"
                      label="Nachname"
                      name="lastname"
                      variant="outlined"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.lastname && errors.lastname)}
                    />
                    <FormHelperText
                      error={Boolean(touched.lastname && errors.lastname)}
                    >
                      {touched.lastname && errors.lastname
                        ? errors.lastname
                        : ""}
                    </FormHelperText>
                  </FormControl>
                  <FormControl fullWidth margin="normal" variant="standard">
                    <TextField
                      id="email"
                      label="E-Mail"
                      name="email"
                      variant="outlined"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.email && errors.email)}
                    />
                    <FormHelperText
                      error={Boolean(touched.email && errors.email)}
                    >
                      {touched.email && errors.email ? errors.email : ""}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={Boolean(touched.password && errors.password)}
                    variant="standard"
                  >
                    <TextField
                      name="password"
                      label="Passwort"
                      type="password"
                      id="password"
                      variant="outlined"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.password && errors.password)}
                    />
                    <FormHelperText
                      error={Boolean(touched.password && errors.password)}
                    >
                      {touched.password && errors.password
                        ? errors.password
                        : ""}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    variant="standard"
                  >
                    <TextField
                      name="confirmPassword"
                      label="Passwort wiederholen"
                      type="password"
                      id="confirmPassword"
                      variant="outlined"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                    />
                    <FormHelperText
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                    >
                      {touched.confirmPassword && errors.confirmPassword
                        ? errors.confirmPassword
                        : ""}
                    </FormHelperText>
                  </FormControl>
                  <FormControl sx={{ display: "none" }}>
                    <TextField
                      name="password999"
                      type="password"
                      variant="outlined"
                      value={values.password999}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!isValid || isSubmitting}
                    sx={{ margin: "24px 0px 16px" }}
                  >
                    Registrieren
                  </Button>
                </Form>
                <Box mt={8}>
                  <Copyright />
                </Box>
              </Container>
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignUp;
