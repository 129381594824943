import { Box, Grid, Paper, Typography } from "@mui/material";

const FeaturePaper = ({ icon: Icon, header, body }) => {
  return (
    <Paper
      sx={{
        margin: "10px auto 0",
        display: "flex",
        flexDirection: "column",
        borderRadius: "1rem",
        maxWidth: {
          xs: "90%",
          lg: "70%",
          xxl: "50%",
        },
      }}
    >
      <Grid
        container
        component="div"
        sx={{
          display: "flex",
          flexFlow: "row wrap",
          alignItems: "center",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid
            container
            component="div"
            sx={{
              display: "flex",
              flexFlow: "row wrap",
              alignItems: "center",
            }}
          >
            <Grid item xs={2}>
              <Box>
                <Icon
                  sx={{
                    color: "#547890",
                    fontSize: {
                      xs: 40,
                      md: 60,
                      lg: 80,
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box>
                <Typography
                  component="div"
                  variant="h6"
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      lg: "1.25rem",
                    },
                  }}
                >
                  {header}
                </Typography>

                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  component="div"
                  sx={{
                    fontSize: {
                      xs: "0.8rem",
                      lg: "0.875rem"
                    },
                    lineHeight: {
                      xs: 1.2,
                      lg: 1.57
                    },
                  }}
                >
                  {body}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
};

export default FeaturePaper;
