import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom/";
import queryString from "query-string";

import { Box } from "@mui/material";

import GameDataService from "src/services/game.service";
import PlayerDataService from "src/services/player.service";
import LoanDataService from "src/services/loan.service";
import { useAlertMessageStore, useHeaderStore } from "src/store/store";

import Loading from "src/components/Loading";
import LoanForm from "src/components/form/loanForm";

const AddLoan = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [allGames, setAllGames] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [game, setGame] = useState(null);

  const { collectionId, gameId } = useParams();

  useEffect(() => {
    useHeaderStore.setState({ title: "Leihe erfassen", help: "addLoan" });
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      const allPlayers = await PlayerDataService.getAll(collectionId);
      setAllPlayers(allPlayers.data.data.players);
      const game = await GameDataService.fetchById({
        collectionId: collectionId,
        gameId: gameId,
        params: "simple=true",
      });
      setGame(game);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.status === 404) {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Das Spiel konnte nicht gefunden werden",
          },
        });
        history.push("/");
      } else {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Laden der Daten",
          },
        });
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      await LoanDataService.create(values).then((response) => {
        const loan = response.data.loan;
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: "Die Leihe wurde erfolgreich erfasst",
          },
        });
        history.push(`/loans/${loan.id}`);
      });
    } catch (error) {
      setIsLoading(false);
      useAlertMessageStore.setState({
        alertMessage: {
          severity: "error",
          message: "Fehler beim erfassen der Leihe",
        },
      });
    }
  };

  const initialValues = {
    game: game,
    loan_to: null,
    description: "",
    loan_start: "",
    loan_end: "",
  };

  return (
    <Box>
      {isLoading && <Loading />}
      <LoanForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        allGames={allGames}
        allPlayers={allPlayers}
        isSubmitting={isLoading}
      />
    </Box>
  );
};

export default AddLoan;
