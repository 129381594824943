import React, { Component } from "react";
import moment from 'moment';
import EventDataService from "../../services/event.service";
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Link } from 'react-router-dom';


import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
} from '@mui/material';


const useStyles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  chevron: {
    textAlign: 'right'
  },
  icons: {
    verticalAlign: 'middle',
  },
  Abgelehnt: {
    color: theme.palette.error.main,
  },
  Angenommen: {
    color: theme.palette.success.main,
  },
  Ausstehend: {
      color: theme.palette.warning.main,
  },
  Ersteller: {
      color: 'black',
      fontWeight: 'bold',
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  speedDialEvent: {
    position: 'inherit',
    maxWidth: 0,
    maxHeight: 0,
    marginLeft: 'auto',
    marginRight: '20px',
    '& .MuiFab-primary': {
      color: '#333',
      boxShadow: 'none',
      background: 'transparent',
    }
  },
});


class Events extends Component {

  constructor(props) {
    super(props);
    this.handleChangeSpeedDialEvent = this.handleChangeSpeedDialEvent.bind(this);
    this.handleChangeSpeedDial = this.handleChangeSpeedDial.bind(this)    

    this.props.setStateValue('isLoading', true)
    this.props.setStateValue('pageTitle', 'Events')
    this.props.setStateValue('help', false);

     this.state = {
       eventError: "",
       events: [],

       openSpeedDial: false,
    }
  }
 
  componentDidMount() {
    this.retrieveEvents();
  }  

retrieveEvents() {
  EventDataService.getAll()
    .then(response => {
      this.setState({
        events: response.data.data,
      })
      this.props.setStateValue('isLoading', false)
    })
    .catch(err => console.log(err))
}

handleChangeSpeedDialEvent(id) {
  this.setState({
    openSpeedDialEvent: id
  })
}

handleChangeSpeedDial() {
  this.setState({
    openSpeedDial: !this.state.openSpeedDial
  })
}

render() {
  const { events, openSpeedDialEvent, openSpeedDial } = this.state
  const { classes } = this.props;

  return (
  <React.Fragment>
    <div className={classes.root}>
    <List component="nav">
      {events?.map((event) => (
        <ListItem>
          <ListItemText primary={<>{event.title}</>} 
          secondary={<><EventIcon className={classes.icons} /> {moment(event.date).format("DD.MM.YYY")} <ScheduleIcon className={classes.icons} /> {moment(event.time, "HH:mm:ss").format("HH:mm")} <br /> 
          { event.location && (<><LocationOnIcon className={classes.icons} /> {event.location} <br /> </>) }
          <span className={classes[event.status]}>{event.status}</span></>} />
          <ListItemSecondaryAction>
              <SpeedDial
                  ariaLabel="Eventmenu"
                  color="secondary"
                  className={classes.speedDialEvent}
                  icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
                  direction="left"
                  key={event.id}
                  open={openSpeedDialEvent === event.id}
                  onOpen={() => this.handleChangeSpeedDialEvent(event.id)}
                  onClose={() => this.handleChangeSpeedDialEvent(null)}
              >
                  { event.status === 'Ersteller' ?
                  [
                  <SpeedDialAction key="Bearbeiten" icon={<EditIcon />} tooltipTitle="Event bearbeiten" component={Link} to={`/events/editEvent/${event.id}`} /> ,
                  <SpeedDialAction key="Löschen" icon={<DeleteIcon color='error' />} tooltipTitle="Event löschen" />,
                  <SpeedDialAction key="Kopieren" icon={<FileCopyIcon />} tooltipTile="Link zum Event kopieren" onClick={() => {navigator.clipboard.writeText(`/event/${event.id}`)}} />
                  ]
                  :
                  [
                  <SpeedDialAction key="Ablehnen" icon={<ClearIcon color='error' />} tooltipTitle="Ablehnen" />,
                  <SpeedDialAction key="Akzeptieren" icon={<CheckIcon color='success' />} tooltipTitle="Akzeptieren" />
                  ]
                  }
              </SpeedDial>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
    <SpeedDial ariaLabel="Eventmenu" className={classes.speedDial} icon={<SpeedDialIcon icon={<MoreVertIcon />} />} open={openSpeedDial} onOpen={this.handleChangeSpeedDial} onClose={this.handleChangeSpeedDial} >
      <SpeedDialAction key="add" icon={<AddIcon color="primary"/>} tooltipTitle="Event hinzufügen" component={Link} to={`/events/addEvent`}/>
    </SpeedDial>   
    </div>
    </React.Fragment>
  )
  
}
}

export default withStyles(useStyles)(Events)