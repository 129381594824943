import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { Link } from "react-router-dom";

// component props interface

// styled component
const StyledLink = styled(Link)(({ theme, active }) => ({
  position: "relative",
  transition: "color 150ms ease-in-out",
  color: active ? theme.palette.primary.main : "inherit",
  "&:hover": {
    color: `${theme.palette.primary.main} !important`,
  },
}));
const NavLink = ({ href, children, style, className, ...props }) => {
  const { pathname }  = useLocation();
  const checkRouteMatch = () => {
    if (href === "/") return pathname === href;
    return pathname.includes(href);
  };

  // active route
  const currentRoute = checkRouteMatch();
  return (
    <StyledLink
      to={{
        pathname: href
      }}
      style={style}
      className={clsx(className)}
      active={currentRoute ? 1 : 0}
      {...props}
    >
      {children}
    </StyledLink>
  );
};
export default NavLink;
